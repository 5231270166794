import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import BlogContentDetails from '../components/blog/BlogContentDetails';
import FooterOne from '../common/footer/FooterOne';

const BlogDetails = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Blog Details Page"
                currentPage="Blog Details Page" 
            />
            <BlogContentDetails />
            <FooterOne />
        </>
    )
}

export default BlogDetails;