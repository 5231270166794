import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UrlConstants } from '../../utils/UrlConstants';

const BusinessCategories = (props) => {
    const { categories, navigateToCateoryDetails } = props;
    let publicUrl = process.env.PUBLIC_URL+'/';

    const [searchCategory, setSearchCategory] = useState('');

    const filterCategories = (query) => {
        return categories.filter(category => 
          category.name.toLowerCase().includes(query.toLowerCase())
        );
    };
    const filteredCategories = filterCategories(searchCategory);
    const clearSearch = () => {
        setSearchCategory('');
    };

    const renderCategoryItem = () => {
        return(
            <div className="row masonary-layout">
            {filteredCategories?.map((item, index)=>{
                 const imgUrl = item?.image_url ? `${UrlConstants.apiUrls.baseUrl}${item.image_url}` : `${publicUrl}assets/images/resources/place-v1-img1-cate.jpg`;
                return(
                  <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.1s">
                    <button onClick={() => navigateToCateoryDetails(item?.id)}>
                    <div className="categories-one__single">
                        <div className="categories-one__single-img">
                            <img src={imgUrl} alt="" />
                            <div className="text-box">
                                <h2>{item?.name}</h2>
                            </div>
                        </div>
                        </div>
                    </button>
                </div>
                )
            })
                
        }
        </div>
        );
    };

    const renderCategorySearch = () => { 
        return (
            <div className="tab active-tab category-search-wrappper">
                <div className="banner-one__tab-content-item">
                    <div className="banner-one__tab-content-places">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="banner-one__tab-content-places-form">
                                    <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                        <ul>
                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="fas fa-keyboard"></span>
                                                    </div>
                                                    <input type="text" placeholder="Search Categories Here..." name="name" onChange={(e) => setSearchCategory(e.target.value)} value={searchCategory} />
                                                </div>
                                            </li>

                                            <li>
                                                {searchCategory && <button className="thm-btn comment-form__btn" type="button" data-loading-text="Please wait..." onClick={clearSearch}>
                                                    <span className="icon-cross"></span>
                                                </button>}
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
    <>
        {/* Start Categories One */}
        <section className="categories-one categories-one--two busi-categories-one--two">
             <div className="container">
                    {
                        renderCategorySearch()
                    }
                <div className="sec-title text-center">
                    <h2 className="sec-title__title">Business Categories</h2>
                </div>
                {
                    renderCategoryItem()
                }
            </div>
        </section>
        {/* End Categories One */}
    </>
    )
};

export default BusinessCategories;



{/* <div className="row masonary-layout">

<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.1s">
    <Link to={process.env.PUBLIC_URL + `/listings`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img1-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>E Sector</h2>
            </div>
        </div>
        </div>
    </Link>
</div>

    
<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.2s">
    <Link to={process.env.PUBLIC_URL + `/categories`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img2-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>IT Services</h2>
            </div>
        </div>
        </div>
    </Link>
</div>


<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.3s">
    <Link to={process.env.PUBLIC_URL + `/categories`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img3-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>Digital</h2>
            </div>
        </div>
        </div>
    </Link>
</div>

 
<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.4s">
    <Link to={process.env.PUBLIC_URL + `/categories`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img4-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>Media</h2>
            </div>
        </div>
        </div>
    </Link>
</div>


<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
    <Link to={process.env.PUBLIC_URL + `/categories`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img5-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>Pet service</h2>
            </div>
        </div>
        </div>
    </Link>
</div>


<div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.6s">
    <Link to={process.env.PUBLIC_URL + `/categories`}>
    <div className="categories-one__single">
        <div className="categories-one__single-img">
            <img src={publicUrl+"assets/images/resources/place-v1-img6-cate.jpg"} alt="" />
            <div className="text-box">
                <h2>Hospital</h2>
            </div>
        </div>
        </div>
    </Link>
</div>



</div> */}