import React, { useEffect, useState } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import BusinessListing from '../components/listing/BusinessListing';
import FooterOne from '../common/footer/FooterOne';
import SingleListItem from '../components/listing/SingleListingItem';
import { businessServices } from '../services';
import { ShopStatusConstant } from '../utils/Constants';
import { useParams } from 'react-router-dom';
import { getCategoryName, getCategoryNameByIds } from '../utils/CommonFunctions';
import NoRecordFound from '../components/noRecordFound/NoRecordFound';

const BusinessListingPage = () => {
    const {id} = useParams('id');
    const [categories, setCategories] = useState([]);
    const [businessListing, setBusinessListing] = useState([]);
    console.log('pssss', id)
    useEffect(()=>{
        getCategoriesList();
        getBusinessListing();
    },[]);
    
    const getCategoriesList = () => {
        businessServices.GetAllCategories()
        .then((res)=>{
          if(res.data.status){
            const {data} = res.data;
            setCategories(data)
            localStorage.setItem('categories', JSON.stringify(data));
          }      
          console.log('succesfully categories', res);
        })
        .catch((e)=>{
           console.log('packages err', e);
           let msg = e?.response?.data?.message;
           if(!msg && e?.response?.statusText){
             msg = e?.response?.statusText;
           }
           console.log('error loading categories', msg);
        //    setAuthApiErrMsg(msg);
        });
    };

    const getBusinessListing = () => {
      businessServices.GetShopDetailsByCategory({
        category_id: parseInt(id),
        active_status: ShopStatusConstant.published,
        sortBy: 'viewers_count'
      })
      .then((res)=>{
        if(res.data.status){
          const {data} = res.data;
          setBusinessListing(data);
           console.log('succesfully listing', data);

          // setCategories(firstFourItems)
          
        }      
      })
      .catch((e)=>{
         console.log('packages err', e);
         let msg = e?.response?.data?.message;
         if(!msg && e?.response?.statusText){
           msg = e?.response?.statusText;
         }
         console.log('error loading categories', msg);
      //    setAuthApiErrMsg(msg);
      });
  };

    return (
     <>
      <HeaderOne />
      <Breadcrumb 
        heading={getCategoryNameByIds(categories, parseInt(id)) ?? "Business Listing"}
        currentPage="Business Listing" 
        Img="category-bannner.jpg"
      />
        {
          businessListing?.length > 0 && <BusinessListing 
          businessListing={businessListing}
          categories={categories}
          categoryId={parseInt(id)}  
        />  }
        {
          businessListing?.length === 0 && <NoRecordFound message={`No business listings found under ${getCategoryNameByIds(categories, parseInt(id))} category`} />
        }
        <FooterOne />
     </>
    )
}

export default BusinessListingPage;