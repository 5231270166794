import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { gotoMail, gotoPhone, gotoWhatsApp } from '../../utils/CommonFunctions';
import QRCodeModal from '../modals/QRCodeModal';


const ListingLocationContacts = (props) => {
    const { businessDetails } = props;
    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    const [showMoreContacts, setShowMoreContacts] = useState(false);
    let publicUrl = process.env.PUBLIC_URL+'/';
    const url = `${publicUrl}listings/details/${businessDetails?.id}`;
    const renderMapSection = () => {
        return(
          <div className="sidebar__location-contacts-google-map">
            <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15276.143014155212!2d74.4369288!3d16.8245823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc11b91017f9c1d%3A0xb03f4d6fcb41cc1e!2sSuraj%20Gents%20Parler!5e0!3m2!1sen!2sin!4v1711698429125!5m2!1sen!2sin"
                className="sidebar__location-contacts-map" allowfullscreen>
            </iframe>
           </div>
        );
    };

    const renderContactSection = () => {
      return(
        <ul className="sidebar__location-contacts-list">
           <li><p><i className="icon-map"></i><span>Address :</span> {businessDetails?.address}</p></li>
           <li><p><i className="icon-location"></i><span>Location Url :</span> <a href={businessDetails?.location_url} target='_blank'>{businessDetails?.location_url}</a></p></li>
           <li><p><i className="icon-earth"></i><span>State :</span> {businessDetails?.state}</p></li>
           <li><p><i className="icon-location2"></i><span>District :</span> {businessDetails?.district}</p></li>
           <li><p><i className="icon-location3"></i><span>Taluka :</span> {businessDetails?.tehsil}</p></li>
           <li><p><i className="icon-location-current"></i><span>Pin :</span> {businessDetails?.pin_code}</p></li>
           <li><p><i className="icon-location"></i><span>City :</span> {businessDetails?.city}</p></li>
           <li><p><i className="icon-phone-call"></i> <span> Phone :</span> <a href={`tel:${businessDetails?.user?.phone_number}`}>{businessDetails?.user?.phone_number}</a></p></li>
           <ListGroup>
           {showMoreContacts && businessDetails?.contacts.map((contact, index) => (
              <li key={contact?.id} className='ms-5'><p className='ms-5'><a href={`tel:${contact?.phone_number}`}>{contact?.phone_number}</a></p></li>
            ))}
          </ListGroup>
          {
            <Button onClick={()=> setShowMoreContacts(!showMoreContacts)} variant="link" className="d-flex justify-content-end">
              {showMoreContacts ? 'Show Less' : 'Load More'}
            </Button>
          }
          <li><p><i className="icon-email"></i><span>Mail :</span> <a href="mailto:info@madina.com">{businessDetails?.user?.email}</a></p></li>
          <li><p><i className="icon-list-numbered"></i><span>GST No :</span> {businessDetails?.gst_number}</p></li>
        </ul>
        );
    };

    const renderSocialIcons = () => {
      return(
        <div className="social-media-contact-sidebar">
          <div className="social-media-contact">
            <Link onClick={() => gotoMail(businessDetails?.user?.email)}><span className="icon-gmail"></span></Link>
            <Link onClick={() => gotoPhone(businessDetails?.user?.phone_number)}><span className="icon-call"></span></Link>
            <Link onClick={() => gotoWhatsApp(businessDetails?.wp_url)}><span className="icon-whatsapp"></span></Link>
            <Link><span className="icon-qr_code_scanner" onClick={() => setShowQrCodeModal(true)}></span></Link>
            <Link to={businessDetails?.insta_url} target='_blank'><span className="icon-instagram"></span></Link>
            <Link to={businessDetails?.location_url} target='_blank' className="google-map-icon">
              <img src={publicUrl + "assets/images/icon/google-map.png"} alt="#" className="google-map-img" /></Link>
          </div>
        </div>
       );
    };

    return (
      <>
        <div className="listings-details-page__sidebar-single sidebar__location-contacts wow animated fadeInUp" data-wow-delay="0.2s">
            <div className="title">
                <h2>Location / Contacts</h2>
            </div>
            {/* {renderMapSection()} */}
            {renderContactSection()}
            {/* {renderSocialIcons()} */}
             <QRCodeModal 
               open={showQrCodeModal}
               handleClose={() => setShowQrCodeModal(false)}
               url={url}
              />
          </div>
        </>
      );
};

export default ListingLocationContacts;
