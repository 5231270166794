import React from 'react';
import { Link } from 'react-router-dom';

export default class HowWeWork extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="how-we-work-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">How We Work</h2>
                        </div>
                        <div className="how-we-work-info">
                            <div className="default-title">
                                <h5>Creative Excellence</h5>
                            </div>
                            <div className="default-desc">
                                <p>Our team of creative minds designs captivating visuals and impactful content that resonates with your target audience, leaving a lasting impression.</p>
                            </div>
                            <div className="default-title">
                                <h5>Proven Strategies</h5>
                            </div>
                            <div className="default-desc">
                                <p>With years of experience, we have honed our strategies to perfection. We leverage the latest industry trends while staying true to the core principles of effective marketing.</p>
                            </div>
                            <div className="default-title">
                                <h5>Dedicated Support</h5>
                            </div>
                            <div className="default-desc">
                                <p>When you partner with the Build Busines Growth Team, you gain a dedicated team that's invested in your success. We work closely with you to understand your goals and guide you through every step of your growth journey.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}