import React from 'react';

export default class AboutUsInfoTwo extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".odometer").length) {
            var odo = $(".odometer");
            odo.each(function () {
              $(this).appear(function () {
                var countNumber = $(this).attr("data-count");
                $(this).html(countNumber);
              });
            });
        }
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Counter One */}
                <section className="counter-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Build Busines Story</h2>
                            <p className="sec-title__text">Get new customers everyday</p>
                        </div>
                        <div className="row">
                            {/* Start Counter One Single */}
                            <div className="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="about-us-info">
                                   <div className="about-us-que">
                                         <h5>Our Background and Our Journey Our Story </h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>We’re more than just a digital Advertising company. We've got goals and dreams too - the same as every other person out there! We want our clients to be successful! With us as your partner in professional life’s adventures.</p>
                                    </div>
                                    <div className="about-us-que">
                                         <h5>We have the know-how and the drive to help your business succeed.</h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>Mahendra Doijad are the founders of Build Busines Advertising Private Limited, INDIAS most recognized Search Advertising Company. When Mahendra Doijad started there digital search Advertising company, Build Busines in 2021, there were not many people who understood the potential of the Advertising Platform. Today, Build Busines is not only the youngest company in INDIA but is one of the leading advertising company in INDIA.</p>
                                    </div>
                                </div>
                                <div className="about-title-story text-center">
                                    <h3>"We have always been a technology buff. Everything tech inspires me. Google was still like a magic wand, and the idea of optimizing the websites to appear in top for searches was fascinating and it was new. This is what got we to start our company.”</h3>
                                </div>
                            </div>
                            {/* End Counter One Single */}

                            
                        </div>
                    </div>
                </section>
                {/* End Counter One */}
            </>
        )
    }
}