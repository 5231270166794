import React from 'react';

export default class TeamClientHire extends React.Component {
    
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Counter One */}
                <section className="counter-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Why Our Clients Hire Us</h2>
                            <p className="sec-title__text">We're results-driven, and always focused on achieving our clients' goals</p>
                        </div>
                        <div className="row">
                            {/* Start Counter One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="counter-one__single">
                                    <div className="counter-one__single-bg"></div>
                                    <div className="counter-one__single-top">
                                        <div className="team-counter-box">
                                          <span className="icon-office"></span>
                                        </div>
                                        <div className="title">
                                            <h3>Worked with Different Industries</h3>
                                        </div>
                                    </div>
                                    <div className="counter-one__single-bottom">
                                        <p>Build Busines Advertising Team has a vast experience of working with different industries. It has helped clients in various niches to attain their desired ranking and visibility on the web.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Counter One Single */}

                            {/* Start Counter One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="counter-one__single">
                                    <div className="counter-one__single-bg"></div>
                                    <div className="counter-one__single-top">
                                        <div className="team-counter-box">
                                           <span className="icon-chart-pie"></span>
                                        </div>
                                        <div className="title">
                                            <h3>Demonstrate Tangible Results</h3>
                                        </div>
                                    </div>

                                    <div className="counter-one__single-bottom">
                                        <p>We have a long history of delivering tangible results for our clients. Our team is an experts in a wide range of digital marketing disciplines, including SEO, paid search, social media marketing, and content marketing.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Counter One Single */}
                            {/* Start Counter One Single */}
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="counter-one__single">
                                    <div className="counter-one__single-bg"></div>
                                    <div className="counter-one__single-top">
                                        <div className="team-counter-box">
                                           <span className="icon-cogs"></span>
                                        </div>
                                        <div className="title">
                                            <h3>Proven Methodology</h3>
                                        </div>
                                    </div>

                                    <div className="counter-one__single-bottom">
                                        <p>We are one of the few companies that follow a methodology for our digital marketing services that has been proven to work. We have a team of experienced professionals who understand how to execute campaigns that deliver results.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Counter One Single */}
                        </div>
                    </div>
                </section>
                {/* End Counter One */}
            </>
        )
    }
}