import React from 'react';
import { Link } from 'react-router-dom';

export default class AboutTwo extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="about-one about-one--two">
                    <div className="shape4"><img src={publicUrl+"assets/images/shapes/about-v1-shape4.png"} alt="#" /></div>
                    <div className="container">
                        <div className="row">
                            {/* Start About One Left */}
                            <div className="col-xl-6">
                                <div className="about-one__left">
                                    <div className="about-one__left-img">
                                        <div className="shape1 float-bob-y"></div>
                                        <div className="shape2 float-bob-y"></div>
                                        <div className="shape3 float-bob-y"></div>
                                        <img src={publicUrl+"assets/images/about/about-us.jpg"} alt="#" />
                                    </div>
                                     
                                </div>
                            </div>
                            {/* End About One Left */}

                            {/* Start About One Right */}
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__right-title">
                                        <h2 className='sec-title__title'>About Us</h2>
                                    </div>
                                    <div className="about-one__right-text1">
                                        <p>Build Busines Advertising</p>
                                        <p>Founded in 2024 Build Busines advertising Pvt Ltd. Company set out itself to be a leading services like digital services that promotes or do branding of shops and every kind of business.This are unique services that do branding of each big and small startup businesses or every type of skill person who wants to promote himself digitally.</p>
                                         <p>Our Services is more faster and Quick to search their shop/ business  OR Specific skilled person on our Platform.</p>
                                    </div>
                                   
                                    <div className="about-one__right-text1">
                                        <p>Build Busines Advertising is India's largest online B2B marketplace, connecting buyers with suppliers.</p>
                                    </div>
                                    <div className="about-one__right-text2">
                                        <p>The channel focuses on providing a platform of Unique Service that do Branding of Each and Every small or big startup business owner or Shop or every type of skill person who wants to promote himself Digitally.</p>
                                        <p>Build Busines is India based Digital Advertising company with its offices in New Pune, Mumbai and Bangalore founded in 2024 by Mahendra Doijad with a mission to help clients get new customers everyday.</p>
                                    </div>
                                    
                                   
                                    
                                </div>
                            </div>
                            {/* End About One Right */}
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="about-one__right-text2 text-center">
                                        <h2 class="sec-title__title">Mission</h2>
                                        <p>To deliver innovative and effective integrated brand marketing solutions that help our customers. We aim to build strong and profitable services by delivering to our customers professional service that is always fair efficient helpful and responsive</p>
                                        <p>Start today – it's easy.</p>
                                        <p>If you need help, there's 24/7 email, chat and phone support from a real person. Get started</p>
                                     </div>
                                    <div className="about-one__right-text2 text-center">
                                          <h2 class="sec-title__title">Vision</h2>
                                        <p>Our company comitted to vision to be best advertising services. To be the preferred advertising service provider among all agencies being recognised for our creativity.</p>
                                    </div>
                            </div>
                         </div>
                        
                    </div>
                </section>
            </>
        )
    }
}