import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { Button, Modal, TOast } from 'react-bootstrap';
import { UrlConstants } from '../../utils/UrlConstants';

const QRCodeModal = (props) => {
    const {open, handleClose, url} = props;
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
      const allUrl = `${UrlConstants.routeUrls.userSite}${url}`;
      navigator.clipboard.writeText(allUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500); // Reset copied state after 1.5 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
    };

    return(
      <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <QRCode value={`${UrlConstants.routeUrls.userSite}${url}`} />
        <p>Scan the QR code to visit: </p>
        <p >{`${UrlConstants.routeUrls.userSite}${url}`}<button className="icon-copy ms-3" onClick={handleCopy}></button></p>
        {isCopied && <span class="alert-success" role="alert">Text Copied!</span>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    )
  };

  export default QRCodeModal;
