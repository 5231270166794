import React, { useEffect, useState } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import BusinessCategories from '../components/categories/BusinessCategories';
import FooterOne from '../common/footer/FooterOne';
import { businessServices } from '../services';
import { useNavigate } from 'react-router-dom';
import { companyLogoUrl } from '../utils/Constants';

const BusinessCategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        getCategoriesList();
    },[]);

    const getCategoriesList = () => {
        businessServices.GetAllCategories()
        .then((res)=>{
          if(res.data.status){
            const {data} = res.data;
            setCategories(data)
            localStorage.setItem('categories', JSON.stringify(data));
          }      
         
        })
        .catch((e)=>{
           console.log('packages err', e);
           let msg = e?.response?.data?.message;
           if(!msg && e?.response?.statusText){
             msg = e?.response?.statusText;
           }
           console.log('error loading categories', msg);
        //    setAuthApiErrMsg(msg);
        });
    };

    const navigateToCateoryDetails = (categoryId) => {
        const url = process.env.PUBLIC_URL + `/listings/${categoryId}`;
        navigate(url);
    };

    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Business Categories"
                currentPage="Business Categories" 
                Img="category-bannner.jpg"
                // CompanyLogo={companyLogoUrl}
            />
            <BusinessCategories categories={categories} navigateToCateoryDetails={navigateToCateoryDetails}/>
            <FooterOne />
        </>
    )
}

export default BusinessCategoriesPage;