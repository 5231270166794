import React from 'react';

export default class TermsAndConditions extends React.Component {
    
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Counter One */}
                <section className="counter-one bg-gray-wrapper">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Terms & Conditions</h2>
                            <p className="sec-title__text">Terms and Conditions for the Website of Dymahi Advertising Pvt. Ltd (WWW.DYMAHIADVERTISING.COM)</p><br/>
                            <p>Upon accessing and utilizing the website WWW.DYMAHIADVERTISING.COM ("the Site"), users hereby acknowledge their understanding and acceptance of the following terms and conditions:</p>
                        </div>
                        <div className="info-wrapper-box">
                             <div className="info-sub-wrapper-box">
                                <h3>Ownership of Content :</h3>
                                <p>The domain WWW.DYMAHIADVERTISING.COM is the exclusive property of Dymahi Advertising Pvt. Ltd. All content, inclusive of text, graphics, images, logos, and software, featured on the Site, is the sole possession of Dymahi Advertising Pvt. Ltd.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Trademarks :</h3>
                                <p>The trademarks "Dymahi Advertising" and its associated logo are the intellectual assets of Dymahi Advertising Pvt. Ltd. Any utilization of these trademarks without prior written consent from Dymahi Advertising Pvt. Ltd. is strictly prohibited.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Privacy Policies :</h3>
                                <p>Users are required to comply with the privacy policies set forth by Dymahi Advertising Pvt. Ltd. These policies are subject to updates or amendments without prior notice to users.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Reservation of Rights :</h3>
                                <p>All content provided on the Site by Dymahi Advertising Pvt. Ltd. or third-party providers is safeguarded as intellectual property and copyrighted works. Dymahi Advertising Pvt. Ltd. reserves the right to take legal action against any misuse of the Site or its content by users.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Limitation of Liability :</h3>
                                <p>Dymahi Advertising Pvt. Ltd. shall not be held liable under any circumstances for direct, indirect, incidental, special, or consequential damages, including but not limited to loss of profits, revenue, data, or use, incurred by users or any third party as a result of accessing or using the Site or its content.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Business Relationships :</h3>
                                <p>Dymahi Advertising Pvt. Ltd. maintains various business associations with customers, suppliers, and other entities. References to "partnership" or "partner" on the Site indicate collaborative activities and shared interests, rather than precise legal relationships.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>License :</h3>
                                <p>Subject to the terms outlined in these Terms of Use, Dymahi Advertising Pvt. Ltd. grants users a limited, non-exclusive, non-transferable right to access, use, and display the Site and its materials. Users agree not to disrupt the operation of the Site in any manner.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Entire Agreement :</h3>
                                <p>These Terms of Use constitute the entire agreement between Dymahi Advertising Pvt. Ltd. and users concerning the use of the Website. Disputes arising from the use of the Website are subject to the jurisdiction of Karad, India. Users are advised to review these terms and conditions carefully before accessing various pages on the Site. Continued use of the Site implies acceptance of these terms and conditions.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3></h3>
                                <p></p>
                             </div>
                        </div>
                    </div>
                </section>
                {/* End Counter One */}
            </>
        )
    }
}