import React, { useEffect, useState } from "react";
import OurValues from "../components/our-values/OurValues";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import { businessServices } from "../services";
import HomePageBanner from "../components/home/HomePageBanner";
import MostPopularListing from "../components/listing/MostPopularListing";
import { ShopStatusConstant } from "../utils/Constants";
import SearchedListing from "../components/listing/SearchedListing";
import Loader from "../components/loader/Loader";
import Toaster from "../components/notify/Toaster";

const HomeDefault = () => {
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [popularListings, setPopularListings] = useState([]);
    const defaultSearchQuery = {business_name: '', city: ''};
    const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
    const [serachedListings, setSerachedListings] = useState([]);
    const [showLoader, setShowLoader] =  useState(false);
    const [showToaster, setShowToaster] =  useState(false);
    const [toasterMsg, setToasterMsg] = useState('');
    useEffect(()=>{
        getCategoriesList();
        getPopularListings();
    },[]);

    useEffect(()=>{
      if(SearchedListing?.length > 0 ) handleScrollToSearch();
    },[serachedListings]);
    
    const getCategoriesList = () => {
        businessServices.GetAllCategories()
        .then((res)=>{
          if(res.data.status){
            const {data} = res.data;
            let firstFourItems = data;
            if (data?.length > 4){ 
              firstFourItems = data?.slice(0, 4);
            }
            setCategories(firstFourItems);
            setAllCategories(data);
            localStorage.setItem('categories', JSON.stringify(data));
          }      
          console.log('succesfully categories', res);
        })
        .catch((e)=>{
           console.log('packages err', e);
           let msg = e?.response?.data?.message;
           if(!msg && e?.response?.statusText){
             msg = e?.response?.statusText;
           }
           console.log('error loading categories', msg);
        //    setAuthApiErrMsg(msg);
        });
    };

    const getPopularListings = () => {
      businessServices.GetShopListByStatus({
        active_status: ShopStatusConstant.published,
        limit: 6,
        sortBy: 'viewers_count'
      })
      .then((res)=>{
        if(res.data.status){
          const {data} = res.data;
          let firstSixItem = data;
          if (data?.length > 4){ 
            firstSixItem = data?.slice(0, 6);
          }
           setPopularListings(firstSixItem);
           console.log('succesfully listing', data);

          // setCategories(firstFourItems)
          
        }      
      })
      .catch((e)=>{
         console.log('packages err', e);
         let msg = e?.response?.data?.message;
         if(!msg && e?.response?.statusText){
           msg = e?.response?.statusText;
         }
         console.log('error loading categories', msg);
      //    setAuthApiErrMsg(msg);
      });
  };
  
  const updateSearchValues = (e) => {
     const {name, value} = e.target;
     setSearchQuery({...searchQuery, [name]: value});
     const checkCleared = isSearching();
     checkCleared && setSerachedListings([]);
  };

  const getSearchedListings = (e) => {
    e.preventDefault();
    setShowLoader(true);
    setToasterMsg('');
    businessServices.GetShopListByStatus({
      active_status: ShopStatusConstant.published,
      sortBy: 'viewers_count',
      ...searchQuery
    })
    .then((res)=>{
      if(res.data.status){
        const {data} = res.data;
        setSerachedListings(data);
        if(data.length === 0){
          setToasterMsg('No record found');
          setShowToaster(true);
        }
      }
      setShowLoader(false);
    })
    .catch((e)=>{
       console.log('packages err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       console.log('error loading categories', msg);
       setShowLoader(false);
    });
   };

   const isSearching = () => {
     if(searchQuery.business_name || searchQuery.city){
      return true;
     };
     return false;
   };

   const handleScrollToSearch = () => {
    const element = document.querySelector('.search-title'); // Get the element by class name
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); // Scroll to the element smoothly
    }
  };

    return (
        <>
            <HeaderOne />
            {showLoader && <Loader />}
            {showToaster && <Toaster showToaster={showToaster} setShowToaster={setShowToaster} message={toasterMsg} />}
            <HomePageBanner categories={categories} updateSearchValues={updateSearchValues} getSearchedListings={getSearchedListings} isSearching={isSearching()}/>
           
             {(isSearching() && serachedListings.length > 0) && <SearchedListing serachedListings={serachedListings} categories={allCategories} />}
            <MostPopularListing popularListings={popularListings} categories={allCategories} />
            <OurValues />
            <FooterOne />
        </>
    )
}

export default HomeDefault;