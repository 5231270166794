import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ListingLocationContacts from './ListingLocationContacts';
import ListingSimilarListings from './ListingSimilarListings';
import ListingTags from './ListingTags';
import ListingWorkingHours from './ListingWorkingHours';
import { gotoMail, gotoPhone, gotoWhatsApp } from '../../utils/CommonFunctions';
import QRCodeModal from '../modals/QRCodeModal';
import { Helmet } from 'react-helmet';

const ListingDetails = (props) => {
    const { businessDetails, categories, similarBusinessList } = props;
    const [enqNumber, setEnqNumber] = useState(businessDetails?.wp_url);
    const [enqMsg, setEnqMsg] = useState('');

    let publicUrl = process.env.PUBLIC_URL + '/'
    useEffect(() => {
        componentDidMount();
    }, [businessDetails?.images?.length>0]);

    useEffect(()=>{
        if(businessDetails) setEnqNumber(businessDetails?.wp_url);
    },[businessDetails]);

    const componentDidMount = () => {
        const $ = window.$;
        if ($('.thm-owl__carousel').length) {
            $('.thm-owl__carousel').owlCarousel({
                loop: true,
                autoplay: true,
                margin: 30,
                nav: false,
                dots: false,
                smartSpeed: 500,
                autoplayTimeout: 3000,
                navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    992: {
                        items: 2
                    },
                    1200: {
                        items: 2
                    }
                }
            });
        }

        if ($(".progress-levels .progress-box .bar-fill").length) {
            $(".progress-box .bar-fill").each(
                function () {
                    $(".progress-box .bar-fill").appear(function () {
                        var progressWidth = $(this).attr("data-percent");
                        $(this).css("width", progressWidth + "%");
                    });
                }, {
                accY: 0
            }
            );
        }

        if ($(".count-box").length) {
            $(".count-box").appear(
                function () {
                    var $t = $(this),
                        n = $t.find(".count-text").attr("data-stop"),
                        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                    if (!$t.hasClass("counted")) {
                        $t.addClass("counted");
                        $({
                            countNum: $t.find(".count-text").text(),
                        }).animate({
                            countNum: n,
                        }, {
                            duration: r,
                            easing: "linear",
                            step: function () {
                                $t.find(".count-text").text(
                                    Math.floor(this.countNum)
                                );
                            },
                            complete: function () {
                                $t.find(".count-text").text(this.countNum);
                            },
                        });
                    }
                }, {
                accY: 0
            }
            );
        }

    }

    const renderImgSection = () => {
        return (
            <div className="listings-details-page__content-img1">
                <img src={businessDetails?.images?.[0]?.image_url ?? publicUrl + "assets/images/resources/listings_details-img1.jpg"} alt="#" width={770} height={510}/>
            </div>
        );
    };

    const renderDescriptionSection = () => {
        return (
            <div className="listings-details-page__content-text-box1 mb-4">
                <div class="views-count"><span class="icon-view-show"></span><span class="count-view">{businessDetails?.viewers_count ?? 0}</span></div>
                <h3>Description</h3>
                <p className="text1">{businessDetails?.business_description}</p>
                <div className="btn-box btn-flex-wrapper">
                    <Link to={businessDetails?.website_url} className="thm-btn" target='_blank'>Visit Website</Link>
                    <Link to={businessDetails?.catelog_url} className="thm-btn" target='_blank'>Shop Catalog</Link>
                </div>
            </div>
        );
    };
      const renderOfferIconSection = () => {
        return (
            <div className="listings-details-page__content-text-box1">
                <h3>Offer Image</h3>
                <div className="offer-icon-wrapper">
                     <img src={businessDetails?.offer_icon_url}  alt="Offer Icon" className="offer-icon-img"/>
               </div>
            </div>
        );
    };

    const renderGallerySection = () => {
        console.log(businessDetails?.images);
        return (
            <div className="listings-details-page__content-gallery">
                <h3>Gallery</h3>
                <div className="row">
                    <div className="col-xl-12">
                        {(businessDetails?.images?.length>0) && <div className="owl-carousel owl-theme thm-owl__carousel listings-details-page__content-gallery--carousel">
                            {
                                businessDetails?.images?.map((img)=>{
                                    return(
                                        <div className="listings-details-page__content-gallery-single" key={`gallary_${img.id}`}>
                                            <div className="listings-details-page__content-gallery-single-img">
                                                <img src={img?.image_url} alt="#" height={265} width={370}/>
                                                <div className="listings-details-page__content-gallery-single-img-link">
                                                    <a className="img-popup" href={img?.image_url}>
                                                        <span className="icon-plus"></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                            {/* <div className="listings-details-page__content-gallery-single">
                                <div className="listings-details-page__content-gallery-single-img">
                                    <img src={publicUrl + "assets/images/resources/cat-details-gal-1.jpg"} alt="#" />
                                    <div className="listings-details-page__content-gallery-single-img-link">
                                        <a className="img-popup" href={publicUrl + "assets/images/resources/cat-details-gal-1.jpg"}>
                                            <span className="icon-plus"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="listings-details-page__content-gallery-single">
                                <div className="listings-details-page__content-gallery-single-img">
                                    <img src={publicUrl + "assets/images/resources/cat-details-gal-2.jpg"} alt="#" />
                                    <div className="listings-details-page__content-gallery-single-img-link">
                                        <a className="img-popup" href={publicUrl + "assets/images/resources/cat-details-gal-2.jpg"}>
                                            <span className="icon-plus"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="listings-details-page__content-gallery-single">
                                <div className="listings-details-page__content-gallery-single-img">
                                    <img src={publicUrl + "assets/images/resources/cat-details-gal-3.jpg"} alt="#" />
                                    <div className="listings-details-page__content-gallery-single-img-link">
                                        <a className="img-popup" href={publicUrl + "assets/images/resources/cat-details-gal-3.jpg"}>
                                            <span className="icon-plus"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="listings-details-page__content-gallery-single">
                                <div className="listings-details-page__content-gallery-single-img">
                                    <img src={publicUrl + "assets/images/resources/cat-details-gal-4.jpg"} alt="#" />
                                    <div className="listings-details-page__content-gallery-single-img-link">
                                        <a className="img-popup" href={publicUrl + "assets/images/resources/cat-details-gal-4.jpg"}>
                                            <span className="icon-plus"></span>
                                        </a>
                                    </div>
                                </div>
                            </div> */}


                        </div>}

                    </div>
                </div>
            </div>
        )
    };

    const renderReviewSection = () => {
        return (
            <div className="review_and_progress_bar">
                <div className="review_box">
                    <div className="review_box_details">
                        <h2>4.5</h2>
                        <div className="review-count">
                            <Link to={process.env.PUBLIC_URL + `/`}><i className="icon-star"></i></Link>
                            <Link to={process.env.PUBLIC_URL + `/`}><i className="icon-star"></i></Link>
                            <Link to={process.env.PUBLIC_URL + `/`}><i className="icon-star"></i></Link>
                            <Link to={process.env.PUBLIC_URL + `/`}><i className="icon-star"></i></Link>
                            <Link to={process.env.PUBLIC_URL + `/`} className="clr-gray"><i className="icon-star-1"></i></Link>
                        </div>
                        <p>48 Total Reviews</p>
                    </div>
                </div>
                <div className="progress_bar">
                    <div className="progress-levels">
                        {/* Skill Box */}
                        <div className="progress-box">
                            <div className="text">Rating</div>
                            <div className="inner count-box">
                                <div className="bar">
                                    <div className="bar-innner">
                                        <div className="skill-percent">
                                            <span className="count-text" data-speed="3000"
                                                data-stop="95">0</span>
                                            <span className="percent">%</span>
                                        </div>
                                        <div className="bar-fill" data-percent="95"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    };

    const handleEnqFormUpdate = (e) => {
        const {name, value} = e.target;
        if(name === 'phone_number'){
            if (e.target.value.length <= 10) setEnqNumber(value);
        }else{
            setEnqMsg(value);
        };
    };

    const handleEnqSubmit = (e) => {
        e.preventDefault();
        if(!enqNumber || enqNumber.length < 10){
            alert('Whats app number is not added for this vendor');
            return
        }
        if(!enqMsg){
            alert('Please enter message to send');
            return;
        };
        gotoWhatsApp(enqNumber, enqMsg);
    }

    const renderEnquirySection = () => {
        return (
            <div className="comment-form listings-details-page__content-form">
                <h3 className="comment-form__title">Inquiry form</h3>
                <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                    <div className="row" hidden>
                        <div className="col-xl-12">
                            <div className="comment-form__input-box">
                                <input type="text" placeholder="Mobile Number" name="phone_number" value={enqNumber} onChange={handleEnqFormUpdate} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="comment-form__input-box text-message-box">
                                <textarea name="message" placeholder="Message" onChange={handleEnqFormUpdate} value={enqMsg}></textarea>
                            </div>
                            <div className="comment-form__btn-box">
                                <button type="submit" className="comment-form__btn thm-btn"  onClick={handleEnqSubmit}>Send Inquiry on Whatsapp</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    const url = `${publicUrl}listings/details/${businessDetails?.id}`;
    const renderSocialIcons = () => {
        return(
          <div className="social-media-contact-sidebar mb-10">
            <div className="social-media-contact">
              <Link onClick={() => gotoMail(businessDetails?.user?.email)}><span className="icon-gmail"></span></Link>
              <Link onClick={() => gotoPhone(businessDetails?.user?.phone_number)}><span className="icon-call"></span></Link>
              <Link onClick={() => gotoWhatsApp(businessDetails?.wp_url)}><span className="icon-whatsapp"></span></Link>
              <Link><span className="icon-qr_code_scanner" onClick={() => setShowQrCodeModal(true)}></span></Link>
              <Link to={businessDetails?.insta_url} target='_blank'><span className="icon-instagram"></span></Link>
                    <Link to={businessDetails?.location_url} target='_blank' className="google-map-icon">
                        <img src={publicUrl + "assets/images/icon/google-map.png"} alt="#" className="google-map-img" /></Link>
              <Link to={businessDetails?.fb_url} target='_blank'><span className="icon-facebook"></span></Link>
            </div>
            <QRCodeModal 
               open={showQrCodeModal}
               handleClose={() => setShowQrCodeModal(false)}
               url={url}
              />
          </div>
         );
      };

    return (
        <>
            {/* Start Listings Details Page */}
            <section className="listings-details-page">
                <Helmet>
                    <title>{businessDetails?.business_name ?? 'Build Busines Advertising'}</title>
                    <meta name="description" content={businessDetails?.business_description ?? 'Build Busines Advertising'}/>
                    <meta property="og:title" content={businessDetails?.business_name}/>
                    <meta property="og:description" content={businessDetails?.business_description ?? 'Build Busines Advertising'}/>
                    <meta property="og:image" content={businessDetails?.images?.[0]?.image_url ?? publicUrl + "assets/images/resources/listings_details-img1.jpg"}/>
                    {/* Other meta tags */}
                </Helmet>
                <div className="container">
                    <div className="row">
                        {/* Start Listings Details Page Content */}
                        <div className="col-xl-8 col-lg-7">
                            <div className="listings-details-page__content">
                                {renderImgSection()}
                                {renderSocialIcons()}
                                {renderDescriptionSection()}
                                {renderOfferIconSection()}
                                {renderGallerySection()}
                                {renderReviewSection()}
                                
                            </div>
                        </div>
                        {/* End Listings Details Page Content */}

                        {/* Start Listings Details Page Sidebar */}
                        <div className="col-xl-4 col-lg-5">
                            <div className="listings-details-page__sidebar">
                                <ListingWorkingHours
                                  businessDetails={businessDetails}
                                />
                                <ListingLocationContacts 
                                  businessDetails={businessDetails}
                                />
                                <ListingSimilarListings similarBusinessList={similarBusinessList}/>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7">
                            <div className="listings-details-page__content">
                                {renderEnquirySection()}
                             </div>
                        </div>
                        {/* End Listings Details Page Sidebar */}
                    </div>
                </div>
            </section>
            {/* End Listings Details Page */}
        </>
    )
};

export default ListingDetails;

