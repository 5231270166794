import React from 'react';
import { Link } from 'react-router-dom';
import TeamClientHire from './TeamClientHire';
export default class TeamDetails extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="team-one ">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Our Team  Is What Makes Us Great </h2>
                            <p className="sec-title__text">We are an ambitious, diverse team of 5 web and SEO experts, living in India. we have over 18+ years of digital marketing experience!</p>
                        </div>
                        
                        <div className='our-team-info'>
                              <div className='our-team-info-head'>
                                <h2 className="sec-title__title">OUR TEAM IS HERE FOR YOU!</h2>
                                <p className="sec-title__text">Providing The Best Experience In Digital Marketing</p>
                                <p>In the last two decades we've been providing search engine optimization services and every year our team continues to grow.</p>
                                    <p>From a team of 5 with office in one location, we have steadily and surely grown to a team of 14+ certified web experts with offices in 7 locations across the INDIA</p>
                                </div>
                            <div className="our-team-que">
                                <h5> Marketing Team :-</h5>
                            </div>
                            <div className="our-team-desc">
                                <p>We are dealing in service base product. i.e. Digital service that promotes or do Branding of Shops & every kind of Business. Who ever wants to promote or need to brand themselves they need Our Platform. Not only shops also any kind of skill person can also take benifit of our products to Promote themselves. It means our Platform is for every person who wants to do digital branding of their skill, shop, business, institute, coaching classes etc.</p>
                            </div>
                            <div className="our-team-que">
                                <h5> EO Team :-</h5>
                            </div>
                            <div className="our-team-desc">
                                <p>The SEO team is almost invisible to the naked eye. At least that is the general impression in our office. Their presence is imperceptible, they hardly converse with the rest of the office. They are the ones who eat, drink and sleep SEO.</p>
                            </div>
                            <div className="our-team-que">
                                <h5>Linkbuilding Team :-</h5>
                            </div>
                            <div className="our-team-desc">
                                <p>Since the very beginning we have stressed on the quality vis a vis the quantity of links. Our astute link builders have enabled us to practise what we preach.We see them as icons of patience and perseverence, which creates problems for them at times.</p>
                            </div>
                            <div className="our-team-que">
                                <h5>Designing Team :-</h5>
                            </div>
                            <div className="our-team-desc">
                                <p>These guys are behind the colorful walls of our office. Born creative, our designers still work with pen and pencil because we believe a good web design is always conceived in the mind.</p>
                            </div>
                            <div className="our-team-que">
                                <h5>Content Marketing Team :-</h5>
                            </div>
                            <div className="our-team-desc">
                                <p>Honestly speaking, we at Build Busines Advertising think of them as castlebuilders. Though not totally in the air and not totally without doors. They build beautiful castles that attract, charm and let the reader in.</p>
                            </div>
                        </div>
                        <TeamClientHire/>
                    </div>
                </section>
            </>
        )
    }
}