export const UserTypeConstant = {
    admin: "admin",
    agent: "agent",
    distributor: "distributor",
    vendor: "vendor"
};

export const PageName = {
    distributors: 'distributors',
    agents: 'agents',
    vendors: 'vendors'
};

export const DistributorPageVisiblity = [
    PageName.vendors,
    PageName.agents
];
export const AgentsPageVisiblity = [
    PageName.vendors
];

export const UsersPagePageVisiblity = [
    PageName.vendors
];

export const VendroSteps = {
    packageDetails:{
        name: 'Package Details',
        step: 1
    },
    useDetails:{
        name: 'User Details',
        step: 2
    },
    shopDetails:{
        name: 'Shop Details',
        step: 3
    }
};

export const ShopSteps = {
    shopDetails:{
        name: 'Shop Details',
        step: 1
    },
    contactDetails:{
        name: 'Contact Details',
        step: 2
    },
    shopImages:{
        name: 'Shop Images',
        step: 3
    }
};

export const AgentSteps = {
    basicDetails:{
        name: 'Basic Details',
        step: 1
    },
    bankingDetails:{
        name: 'Banking Details',
        step: 2
    }
};

export const AgentTableHeaders = ['#', 'Name', 'Phone', 'Email', 'Joining Date', 'Action'];

export const VendorTableHeaders = ['#', 'Shop Name', 'Phone', 'Email', 'Address/City', 'Package Name', 'Package Amount', 'Due Date', 'Action' ];

export const PendingVendorTableHeaders = ['#', 'Shop Name', 'Phone', 'Email', 'Address/City', 'Package Name', 'Package Amount', 'Action' ];

export const AgentCommissionHeaders = ['#', 'Agent Name', 'Vendor Name', 'Package Name', 'Package Amount', 'Commission Amount'];

export const UserSubscriptionHeaders = ['#','Package Name', 'Subscription Amount', 'Subscription Date', 'Due Date'];

export const ShopStatusConstant = {
    pending: 'PENDING',
    submitted: 'SUBMITTED',
    published: 'PUBLISHED'
};

export const daysArray = [
    { label:'Monday', value:1 },
    { label:'Tuesday', value:2 },
    { label:'Wednesday', value:3 },
    { label:'Thursday', value:4 },
    { label:'Friday', value:5 },
    { label:'Saturday', value:6 },
    { label:'Sunday', value:7 },
];

export const publicUrl = process.env.PUBLIC_URL+'/';

export const companyLogoUrl = `${publicUrl}assets/images/resources/BB-Logo.jpg`;
