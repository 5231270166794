import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import WhoWeAre from '../components/who-we-are/WhoWeAre';
import WhatWeDo from '../components/who-we-are/WhatWeDo';
import HowWeWork from '../components/who-we-are/HowWeWork';
import WhyUs from '../components/who-we-are/WhyUs';

import FooterOne from '../common/footer/FooterOne';

const WhoWeArePage = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Who We Are"
                Img="values.jpg"
            />
            <WhoWeAre />
            <WhatWeDo />
            <HowWeWork />
            <WhyUs />
            <FooterOne />
        </>
    )
}

export default WhoWeArePage;