import React from 'react';

export default class Breadcrumb extends React.Component {
    render(){
        let heading = this.props.heading;
        const isDynamic = this.props.isDynamic;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let Img = this.props.Img ? this.props.Img : `${publicUrl}assets/images/backgrounds/page-header-img1.jpg`;
        Img = isDynamic ? Img : `${publicUrl}assets/images/backgrounds/${Img}`;
        console.log('imgPath', Img);
        // let Img = this.props.Img ? this.props.Img : 'page-header-img1.jpg';
        let CompanyLogo = this.props.CompanyLogo; 
        // ? this.props.CompanyLogo : 'BB-Logo.png';
        console.log('CompanyLogo',CompanyLogo);
        return (
            <>
                <section className="page-header">
                    <div className="page-header__bg" style={{backgroundImage: 'url('+Img+')'}}></div>
                    {/* <div className="page-header__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/'+Img+')'}}></div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="page-header__wrapper">
                                    <div className="page-header__content text-center">
                                        {CompanyLogo && <img src={CompanyLogo}  alt="Company Logo" className="listing-det-company-logo"/>}
                                        <h2>{heading}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}