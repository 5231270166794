import React from 'react';
import {Link} from "react-router-dom";
import Nav from './Nav';

export default class HeaderOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <header class="main-header main-header-one clearfix">
                    <div class="main-header-one__wrapper">
                        <div class="main-header-one__bottom">
                            <nav class="main-menu main-menu--1">
                                <div class="main-menu__wrapper">
                                    <div class="container">
                                        <div class="main-menu__wrapper-inner">
                                            <div class="main-header-one__bottom-left">
                                                <div class="logo">
                                                    <Link to={process.env.PUBLIC_URL + `/`}><img src={publicUrl+"assets/images/resources/BB-Logo.jpg"} alt="" /></Link>
                                                </div>
                                            </div>

                                            <div class="main-header-one__bottom-right">
                                                <Nav />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}