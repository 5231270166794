export const UrlConstants = {
  routeUrls:{
    login:'/login',
    agents:'/agents',
    addAgent: '/agents/add',
    agentBankDetails: '/agents/bankDetails',
    profile: '/profile',
    editProfile: '/profile/edit',
    vendors: '/vendors',
    addVendor: '/vendors/add',
    shops: '/shops',
    addShop: '/shops/add',
    // adminSite: 'http://69.57.172.247:3005/login',
    adminSite: 'https://admin.buildbusines.com',
    userSite: 'https://www.buildbusines.com',
  },
  apiUrls:{
    // baseUrl:'http://localhost:3001',
    // baseUrl:'http://69.57.172.247:3003',
    baseUrl: 'https://api.buildbusines.com',
    login: '/api/auth/login',
    signup: '/api/auth/signup',
    userByRole: '/api/user/users/role_id',
    createPackage: '/api/package/createPackage',
    getAllPackages: '/api/package/getAllPackages',
    createCategory: '/api/category/createCategory',
    // getAllCategories:'/api/category/getAllCategories',
    getAllCategories:'/api/shop/getAllShopCategories',
    addBankDetails: '/api/bank/createBankRecord',
    getBankDetails: '/api/bank/getBankDetails',
    editUser: '/api/user/users/editUser',
    getUserByEmail: '/api/user/users/email',
    getAllShops: '/api/shop/getAllShopRecords',
    createShopDetails: '/api/shop/createShopRecord',
    editShopDetails:'/api/shop/editShopRecord',
    getShopDetailsByOwnerId: '/api/shop/getShopDetailsByOwnerId',
    getShopDetailsByCategoryId: '/api/shop/getShopDetailsByCategoryId',
    getShopListByStatus: '/api/shop/getShopListByStatus',
    getShopDetailsById: '/api/shop/getShopDetailsById',
    updateViewersCount: '/api/shop/increaseViewersCount'
  }
};
