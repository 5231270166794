import React, { useEffect } from 'react';
import SingleListItem from './SingleListingItem';
import NoRecordFound from '../noRecordFound/NoRecordFound';

const SearchedListing = (props) => {
    const { serachedListings, categories } = props;
    useEffect(()=>{
        componentDidMount();
    },[]);
    
    const componentDidMount = () => {
        const $ = window.$;
        if ($('.masonary-layout').length) {
            $('.masonary-layout').isotope({
                layoutMode: 'masonry'
            });
        };
    };

    return (
        <>
                <section className="place-one">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className="sec-title__title search-title">Searched Listings</h2>
                    </div>
                    <div className="row">
                        {
                            serachedListings?.length === 0 && <NoRecordFound message={'No record found'} />
                        }
                        {(serachedListings && serachedListings.length > 0) &&
                            serachedListings.map((item)=>{
                                return(
                                    <SingleListItem item={item} categories={categories} key={`mostPop_${item?.id}`}/>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default SearchedListing;
