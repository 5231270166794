import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';


const PrivacyPolicyPage = () => {
    return (
        <>
            <HeaderOne />
            <PrivacyPolicy />
            <FooterOne />
        </>
    )
}

export default PrivacyPolicyPage;