import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Footer One */}
                <footer className="footer-one">
                    <div className="footer-one__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-1 col-lg-2 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <Link to={process.env.PUBLIC_URL + `/`}><img src={publicUrl+"assets/images/resources/BB-Logo.jpg"} alt="" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-4 col-lg-4 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    

                                                    <ul className="footer-widget__about-contact-info">
                                                        <li>
                                                            <div className="icon">
                                                               <span className="fa fa-map-marker"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p>
                                                                    G.NO. 158; PLOT NO.62,<br />
                                                                    FLAT NO. 13 GAJANAN PRASAD BHIGWAN ROAD,<br /> JALOCHI BARAMATI <br />
                                                                    DIST- PUNE 413102.
                                                                   
                                                                </p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="fa fa-envelope"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p>support@buildbusines.com</p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="fa fa-phone rotate"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p><a href="tel:9175828496"> +91 9175828496</a></p>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <div className="footer-widget__about-social-link">
                                                        <ul>
                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-facebook-app-symbol"></span>
                                                                    <span className="second icon-facebook-app-symbol"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-instagram"></span>
                                                                    <span className="second icon-instagram"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-twitter"></span>
                                                                    <span className="second icon-twitter"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-linkedin"></span>
                                                                    <span className="second icon-linkedin"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                                <div className="footer-widget__column footer-widget__link">
                                                    <h2 className="footer-widget__title">Quick Link</h2>
                                                    <ul className="footer-widget__link-list">
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/terms-and-conditions`}>Terms & Conditions</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/privacy-policy`}>Privacy Policy</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/packages`}>Our Packages</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/faq`}>FAQ</Link></li>
                                                       
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                                <div className="footer-widget__column footer-widget__map">
                                                    <h2 className="footer-widget__title">Location</h2>
                                                    <div className="footer-map-box">
                                                       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7582.435294610173!2d74.5972965961287!3d18.153885099716735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc39f92c35be893%3A0x66d16d8e5d543c45!2sJalochi%2C%20Maharashtra%20413102!5e0!3m2!1sen!2sin!4v1711860875216!5m2!1sen!2sin"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Footer One Bottom */}
                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner text-center">
                                        <div className="footer-one__bottom-text">
                                            <p>Copyright © 2024 <Link to={process.env.PUBLIC_URL + `/`}>BBA.</Link> All Rights Reserved.</p>
                                            <p>Powered By : <Link to={process.env.PUBLIC_URL + `https://aristonitservices.com/`}>Ariston IT Service</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Footer One Bottom */}
                </footer>
                {/* End Footer One */}
            </>
        )
    }
}