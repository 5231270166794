import React from 'react';

export default class WhyUs extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="why-us-one">
                   <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Why Us</h2>
                            <p className="sec-title__text">We draw learnings from our Experiences of managing 10 thousand  of Ad spendings for leading brands like the following & help in identifying gaps & opportunities in their current advertising strategy & execution.</p>
                        </div>
                        <div className="how-we-work-info">
                            <div className="default-title">
                                <h5>Tailored Solutions</h5>
                            </div>
                            <div className="default-desc">
                                <p>No two businesses are the same, and neither are our strategies. We customize our services to fit your brand's unique needs and objectives.</p>
                            </div>
                            <div className="default-title">
                                <h5>Results that speak</h5>
                            </div>
                            <div className="default-desc">
                                <p>Our track record of success speaks for itself. We have helped businesses across industries achieve remarkable growth and recognition.</p>
                            </div>
                            <div className="default-title">
                                <h5>Holistic Approach</h5>
                            </div>
                            <div className="default-desc">
                                <p>We don't just focus on one aspect of marketing. Our holistic approach covers every facet, ensuring your brand is consistent and impactful across all channels.</p>
                            </div>
                            <div className="default-title">
                                <h5>Transparency</h5>
                            </div>
                            <div className="default-desc">
                                <p>We believe in transparency. You'll have a clear view of your campaign's progress through regular reports and open communication with our team.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}