import React from 'react';
import SingleListItem from './SingleListingItem';

const BusinessListing = (props) => {
    const {businessListing, categories, categoryId} = props;
    return (
      <>
        <section className="categories-one categories-one--two">
         <div className="container">
            <div className="row">
            {
                    businessListing.map((item)=>{
                        return(
                            <SingleListItem item={item} categories={categories}/>
                        )
                    })
            }
            </div>
         </div>
        </section>
        {/* End Categories One */}
      </>
    )
};

export default BusinessListing;
