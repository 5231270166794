import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import OurPackages from '../components/packages/OurPackages';

const PackagesPage = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Our Packages"
                Img="our-packages-ban.jpg"
            />
           <OurPackages/>
            <FooterOne />
        </>
    )
}

export default PackagesPage;