import React from 'react';

export default class WhoWeAre extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="who-we-are-one">
                    <div className="container">
                         <div className="row">
                            {/* Start About One Left */}
                            <div className="col-xl-6">
                                <div className="about-one__left">
                                    <div className="about-one__left-img">
                                        <div className="shape1 float-bob-y"></div>
                                        <div className="shape2 float-bob-y"></div>
                                        <div className="shape3 float-bob-y"></div>
                                        <img src={publicUrl+"assets/images/who-we-are/who-we-are.jpg"} alt="#" />
                                    </div>
                                </div>
                            </div>
                            {/* End About One Left */}

                            {/* Start About One Right */}
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__right-title">
                                        <h2 className='sec-title__title'>Who we are</h2>
                                    </div>

                                    <div className="about-one__right-text1">
                                        <p>The Build Busines Advertising journey began with a <strong>passion</strong> for growing our business for digital advertising. Streaming <strong>Platforms</strong> which has one of the biggest reaches to even Tier 3 towns within India and markets across the <strong>Globe.</strong></p>
                                        <p>The Growth Team have cracked a sure shot way to garner <strong>more visibility</strong> & users and we now want to <strong>share</strong> that with all our future partners in growth.</p>
                                        <p>With a team of creative minds, strategic thinkers, and data-driven experts, we have carved a niche for ourselves in the world of digital advertising.</p>
                                    </div>
                                   
                                </div>
                            </div>
                            {/* End About One Right */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}