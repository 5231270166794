import React from 'react';
import { Link } from 'react-router-dom';

export default class OurPackages extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="our-package-page">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Our Packages</h2>
                            <p className="sec-title__text">We offer various packages for small and medium-scale business user's to join us and start their virtual 24 x 7 business with us.</p>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="1ms" data-wow-duration="1500ms">
                                <div className="packages-main-wrapper">
                                    <div className="package-header">
                                        <h3>Basic</h3>
                                    </div>
                                    <div className="package-info">
                                        <p className="amount-package">3000/Year</p>
                                        <p className="package-com-text">Shop Name</p>
                                        <p className="package-com-text">5 Shop Photo</p>
                                        <p className="package-com-text">Owner name and details</p>
                                        <p className="package-com-text">GST number</p>
                                        <p className="package-com-text">Business Discription</p>
                                        <p className="package-com-text">Rating </p>
                                        <p className="package-com-text">Viewers </p>
                                        <p className="package-com-text">Customer contact details </p>
                                        <p className="package-com-text">Customer Shop Address linked with MAP </p>
                                        <p className="package-com-text">Shop Operating time. </p>
                                        <p className="package-com-text">Live chat on whatsup, Instagram. </p>
                                        <p className="package-com-text">Customer Mail ID  </p>
                                        <p className="package-com-text">Shop webite </p>
                                        <p className="package-com-text">All social media sides with logo Facebook, Instagram, Youtube, Twitter, Hike, snapchat, Telegram</p>
                                        <p className="package-com-text">Similar business modules</p>
                                        <p className="package-com-text">Shop catlog </p>
                                        <p className="package-com-text">2 contact number</p>
                                        <p className="package-com-text">Offer Icon (Enable option )</p>
                                        <p className="package-com-text">New Arrival (Enable option )</p>
                                        <p className="package-com-text">Invoice </p>
                                        <p className="package-com-text">END User feedback and Demand</p>
                                        <p className="package-com-text">Support (Related to query or any)</p>
                                        <p className="package-com-text">MTR (My Tranning Requirement)</p>
                                        <p className="package-com-text">Free trial Check</p>
                                        <p className="package-com-text">Subcription form</p>
                                    </div>
                                    <div className="packages-footer">
                                       <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                             <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="2ms" data-wow-duration="1550ms">
                                <div className="packages-main-wrapper">
                                    <div className="package-header">
                                        <h3>Business</h3>
                                        <div className='ribbon-wrapper-1'>
                                            <div className='ribbon-1'>Basic</div>
                                        </div>
                                    </div>
                                    <div className="package-info">
                                        <p className="amount-package">5000/Year</p>
                                        <p className="package-com-text">Shop Name</p>
                                        <p className="package-com-text">10 Shop Photo</p>
                                        <p className="package-com-text">Owner name and details</p>
                                         <p className="package-com-text">GST number</p>
                                        <p className="package-com-text">Business Discription</p>
                                        <p className="package-com-text">Rating </p>
                                        <p className="package-com-text">Viewers </p>
                                        <p className="package-com-text">Customer contact details </p>
                                        <p className="package-com-text">Customer Shop Address linked with MAP </p>
                                        <p className="package-com-text">Shop Operating time. </p>
                                        <p className="package-com-text">Live chat on whatsup, Instagram. </p>
                                        <p className="package-com-text">Customer Mail ID  </p>
                                        <p className="package-com-text">Shop webite </p>
                                        <p className="package-com-text">All social media sides with logo Facebook, Instagram, Youtube, Twitter, Hike, snapchat, Telegram</p>
                                        <p className="package-com-text">Similar business modules</p>
                                        <p className="package-com-text">Shop catlog </p>
                                        <p className="package-com-text">5 contact number</p>
                                        <p className="package-com-text">Offer Icon (Enable option )</p>
                                        <p className="package-com-text">New Arrival (Enable option )</p>
                                        <p className="package-com-text">Invoice </p>
                                        <p className="package-com-text">END User feedback and Demand</p>
                                        <p className="package-com-text">Support (Related to query or any)</p>
                                        <p className="package-com-text">MTR (My Tranning Requirement)</p>
                                        <p className="package-com-text">Free trial Check</p>
                                        <p className="package-com-text">Subcription form</p>
                                        <p className="package-com-text">Instagram Reel Support</p>
                                        <p className="package-com-text">Video making of your Brand support</p>
                                        <p className="package-com-text">Youtube Shorts support</p>
                                        <p className="package-com-text">Daily Slogans/ Quotes of your brands releted to your business</p>
                                        <p className="package-com-text">12+2 Month Benefit</p>
                                        <p className="package-com-text">TOP  Listing on Company Page</p>
                                    </div>
                                    <div className="packages-footer">
                                       <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                             <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="3ms" data-wow-duration="1600ms">
                                <div className="packages-main-wrapper">
                                    <div className="package-header">
                                        <h3>Ultimate</h3>
                                        <div className='ribbon-wrapper-1'>
                                            <div className='ribbon-1'>Advanced</div>
                                        </div>
                                    </div>
                                    <div className="package-info">
                                        <p className="amount-package">10000/Year</p>
                                        <p className="package-com-text">Shop Name</p>
                                        <p className="package-com-text">10 Shop Photo</p>
                                        <p className="package-com-text">Owner name and details</p>
                                         <p className="package-com-text">GST number</p>
                                        <p className="package-com-text">Business Discription</p>
                                        <p className="package-com-text">Rating </p>
                                        <p className="package-com-text">Viewers </p>
                                        <p className="package-com-text">Customer contact details </p>
                                        <p className="package-com-text">Customer Shop Address linked with MAP </p>
                                        <p className="package-com-text">Shop Operating time. </p>
                                        <p className="package-com-text">Live chat on whatsup, Instagram. </p>
                                        <p className="package-com-text">Customer Mail ID  </p>
                                        <p className="package-com-text">Shop webite </p>
                                        <p className="package-com-text">All social media sides with logo Facebook, Instagram, Youtube, Twitter, Hike, snapchat, Telegram</p>
                                        <p className="package-com-text">Similar business modules</p>
                                        <p className="package-com-text">Shop catlog </p>
                                        <p className="package-com-text">5+ contact number</p>
                                        <p className="package-com-text">Offer Icon (Enable option )</p>
                                        <p className="package-com-text">New Arrival (Enable option )</p>
                                        <p className="package-com-text">Invoice </p>
                                        <p className="package-com-text">END User feedback and Demand</p>
                                        <p className="package-com-text">Support (Related to query or any)</p>
                                        <p className="package-com-text">MTR (My Tranning Requirement)</p>
                                        <p className="package-com-text">Free trial Check</p>
                                        <p className="package-com-text">Subcription form</p>
                                        <p className="package-com-text">Instagram Reel Support</p>
                                        <p className="package-com-text">Video making of your Brand support</p>
                                        <p className="package-com-text">Youtube Shorts support</p>
                                        <p className="package-com-text">Daily Slogans/ Quotes of your brands releted to your business</p>
                                        <p className="package-com-text">12+2 Month Benefit</p>
                                        <p className="package-com-text">TOP  Listing on Company Page</p>
                                        <p className="package-com-text">Referance Benefit</p>
                                        
                                    </div>
                                    <div className="packages-footer">
                                       <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}