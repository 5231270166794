export const getToken = () => {
    const localToken = localStorage.getItem('token');
    return localToken;
};

export const getExpiresAt = () => {
    const expiresAt = localStorage.getItem('expiresAt');
    return expiresAt;
};

export const getUser = () => {
    const user = localStorage.getItem('user');
    return user;
};

export const getLocalCategories = () => {
    let categories = [];
    const localCat = localStorage.getItem('categories');
    if(localCat) categories = JSON.parse(localCat);
    return categories;
};
