import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ServicesMedia from '../components/services/ServicesMedia';
import FooterOne from '../common/footer/FooterOne';

const Services = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Services"
                Img="services-banner.jpg"
            />
            <ServicesMedia />
            <FooterOne />
        </>
    )
}

export default Services;