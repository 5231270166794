import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCategoryName, getFormattedTime, gotoMail, gotoPhone, gotoWhatsApp, isShopOpenNow } from '../../utils/CommonFunctions';
import QRCodeModal from '../modals/QRCodeModal';

const SingleListItem = (props) => {
    let publicUrl = process.env.PUBLIC_URL+'/';
    const {item, categories} = props;
    const [showQrCodeModal, setQrCodeModal] = useState(false);
    const [editUrl, setEditUrl] = useState('');

    const navigate = useNavigate();

    const navigateToDetails = () => {
        // /listings/details/:listingId
        const url = `${publicUrl}listings/details/${item?.id}`;
        navigate(url);
    };

    const img = item?.images?.[0]?.image_url;
    console.log('item image', item);

    return(
        <>
           <QRCodeModal open={showQrCodeModal} handleClose={()=> setQrCodeModal(false)} url={editUrl} />
            <div className="col-xl-4 col-lg-6 col-md-6" key={`listIte_${item?.id}`}>
                <div className="place-one__single">
                    <button onClick={navigateToDetails}>
                        <div className="place-one__single-img">
                            <div className="place-one__single-img-inner">
                                <div className="icon-box icon-verified-box">
                                    <Link to={process.env.PUBLIC_URL + `/`}><span className="icon-verified"></span></Link>
                                </div>
                                <img src={img} alt="" />
                                <div className="ribbon">
                                    <span className="ribbon3">{isShopOpenNow(item?.opening_time, item?.closing_time) ? 'Open Now' : 'Closed Now'}</span>
                                </div>
                            </div>
                            <div className="text-box">
                                <span>{getCategoryName(categories, item)}</span>
                            </div>
                      </div>
                    </button>

                    <div className="place-one__single-content">
                        <div className="views-count">
                                <span className="icon-view-show"></span>
                                <span class="count-view">{item?.viewers_count ?? 0}</span>
                            </div>
                        <div className="top-content">
                            <h2 onClick={navigateToDetails}><Link>{item?.business_name}</Link></h2>
                            <div className="top-content-bottom">
                                <div className="location-box">
                                    <div className="icon-box">
                                        <span className="icon-location"></span>
                                    </div>
                                    <div className="text">
                                        <p>{item?.address}</p>
                                    </div>
                                </div>

                                <div className="info-box">
                                <div className="rating-box">
                                    <ul className="review-box">
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="fa fa-star"></span></li>
                                            <li><span className="icon-star-1"></span></li>
                                        </ul>
                                        <div className="count-box">
                                            <p>(04)</p>
                                        </div>
                                </div>
                                <div className="shope-time-box">
                                    <span className="icon-time"></span>
                                    <p>{`${getFormattedTime(item?.opening_time)}`} to {`${getFormattedTime(item?.closing_time)}`}</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-content">
                            <div className="social-media-contact">
                                <Link><span className="icon-gmail" onClick={() => gotoMail(item?.user?.email)}></span></Link>
                                <Link><span className="icon-call" onClick={() => gotoPhone(item?.user?.phone_number)}></span></Link>
                                <Link><span className="icon-whatsapp" onClick={() => gotoWhatsApp(item?.wp_url)}></span></Link>
                                <Link onClick={()=>{
                                   const url = `${publicUrl}listings/details/${item?.id}`;
                                   setEditUrl(url);
                                   setQrCodeModal(true);
                                }}><span className="icon-qr_code_scanner"></span></Link>
                                <Link to={item?.insta_url} target='_blank'><span className="icon-instagram"></span></Link>
                                <Link to={item?.location_url} target='_blank' className="google-map-icon"><img src={publicUrl + "assets/images/icon/google-map.png"} alt="#"  className="google-map-img"/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleListItem;
