import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import AboutTwo from '../components/about/AboutTwo';
import AboutUsInfoOne from '../components/about/AboutUsInfoOne';
import AboutUsInfoTwo from '../components/about/AboutUsInfoTwo';
import FooterOne from '../common/footer/FooterOne';

const About = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="About Us"
                currentPage="About Page" 
                Img="about-us-banner.jpg"
            />
            <AboutTwo />
            <AboutUsInfoOne />
            <AboutUsInfoTwo />
            <FooterOne />
        </>
    )
}

export default About;