import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// export default class HeroOne extends React.Component {
const HomePageBanner = (props) => {
    const { categories, updateSearchValues, getSearchedListings, isSearching } = props; 
    const navigate = useNavigate();
    useEffect(()=>{
        componentDidMount();
    },[]);

    const componentDidMount = () => {
        const $ = window.$;
        if ($(".slider-bg-slide").length) {
            $(".slider-bg-slide").each(function () {
              var Self = $(this);
              var bgSlideOptions = Self.data("options");
              var bannerTwoSlides = Self.vegas(bgSlideOptions);
            });
        }

        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
              e.preventDefault();
              var target = $($(this).attr("data-tab"));
        
              if ($(target).is(":visible")) {
                return false;
              } else {
                target
                  .parents(".tabs-box")
                  .find(".tab-buttons")
                  .find(".tab-btn")
                  .removeClass("active-btn");
                $(this).addClass("active-btn");
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .fadeOut(0);
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .removeClass("active-tab");
                $(target).fadeIn(300);
                $(target).addClass("active-tab");
              }
            });
        }

        $('select').niceSelect();

    }

    const renderBanner = () => {
      return(
        <div className="banner-one__tab-box">
          <div className="row">
           <div className="col-xl-12">
            <div className="banner-one__tab tabs-box">
               <div className="tabs-content">
                  {/* Start Tab */}
                <div className="tab active-tab" id="places">
                 <div className="banner-one__tab-content-item">
                   <div className="banner-one__tab-content-places">
                     <div className="row">
                        <div className="col-xl-12">
                            <div className="banner-one__tab-content-places-form">
                                {/* <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate"> */}
                                    <ul>
                                        <li>
                                            <div className="comment-form__input-box">
                                                <div className="icon">
                                                    <span className="fas fa-keyboard"></span>
                                                </div>
                                                <input type="text" placeholder="Enter shop name" name="business_name" onChange={updateSearchValues} />
                                            </div>
                                        </li>

                                        <li>
                                            <div className="comment-form__input-box">
                                                <div className="icon">
                                                    <span className="icon-location2"></span>
                                                </div>
                                                <input type="text" placeholder="Enter City" name="city" onChange={updateSearchValues} />
                                            </div>
                                        </li>

                                        {/* <li>
                                            <div
                                                className="comment-form__input-box clearfix">
                                                <div className="icon">
                                                    <span className="icon-list"></span>
                                                </div>
                                                <div className="select-box">
                                                    <select className="selectmenu wide">
                                                        <option selected="selected">
                                                            All Categories</option>
                                                        <option>Shops
                                                        </option>
                                                        <option>Hotels
                                                        </option>
                                                        <option>Restaurants
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li> */}

                                        <li>
                                            <button className="thm-btn comment-form__btn" type="button" data-loading-text="Please wait..." onClick={getSearchedListings} disabled={!isSearching}>Search
                                                <span className="icon-search ms-2"></span>
                                            </button>
                                        </li>
                                    </ul>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {/* End Tab */}

            {/* Start Tab */}
            <div className="tab" id="events">
                <div className="banner-one__tab-content-item">
                    <div className="banner-one__tab-content-events">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="banner-one__tab-content-places-form">
                                    <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                        <ul>
                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="fas fa-handshake"></span>
                                                    </div>
                                                    <input type="text" placeholder="Event Name or Place" name="name" />
                                                </div>
                                            </li>

                                            <li>
                                                <div
                                                    className="comment-form__input-box clearfix">
                                                    <div className="icon">
                                                        <span className="icon-list"></span>
                                                    </div>
                                                    <div className="select-box">
                                                        <select className="selectmenu wide">
                                                            <option selected="selected">
                                                                All Cities</option>
                                                            <option>New York
                                                            </option>
                                                            <option>Dhaka
                                                            </option>
                                                            <option>Paris
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-calendar"></span>
                                                    </div>
                                                    <input type="text" name="form_subject" value="" placeholder="Select Date" id="datepicker" />
                                                </div>
                                            </li>

                                            <li>
                                                <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">Search
                                                    <span className="icon-search"></span>
                                                </button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tab */}

            {/* Start Tab */}
            <div className="tab" id="restaurants">
                <div className="banner-one__tab-content-item">
                    <div className="banner-one__tab-content-restaurants">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="banner-one__tab-content-places-form">
                                    <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                        <ul>
                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-big-cheeseburger"></span>
                                                    </div>
                                                    <input type="text" placeholder="Restaurant Name" name="name" />
                                                </div>
                                            </li>

                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-calendar"></span>
                                                    </div>
                                                    <input type="text" name="form_subject" value="" placeholder="Select Date" id="datepicker2" />
                                                </div>
                                            </li>

                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-user"></span>
                                                    </div>
                                                    <input type="number" placeholder="Persons" name="name" />
                                                </div>
                                            </li>

                                            <li>
                                                <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">Search
                                                    <span className="icon-search"></span>
                                                </button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tab */}

            {/* Start Tab */}
            <div className="tab" id="hotels">
                <div className="banner-one__tab-content-item">
                    <div className="banner-one__tab-content-hotels">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="banner-one__tab-content-places-form">
                                    <form action="assets/inc/sendemail.php"
                                        className="comment-one__form contact-form-validated"
                                        novalidate="novalidate">
                                        <ul>
                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-big-cheeseburger"></span>
                                                    </div>
                                                    <input type="text" placeholder="Hotel Name" name="name" />
                                                </div>
                                            </li>

                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-user"></span>
                                                    </div>
                                                    <input type="number" placeholder="Persons" name="name" />
                                                </div>
                                            </li>

                                            <li>
                                                <div className="comment-form__input-box">
                                                    <div className="icon">
                                                        <span className="icon-calendar"></span>
                                                    </div>
                                                    <input type="text" name="form_subject" value="" placeholder="Select Date" id="datepicker-inline" />
                                                </div>
                                            </li>


                                            <li>
                                                <button className="thm-btn comment-form__btn" type="submit">Search
                                                    <span className="icon-search"></span>
                                                </button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Tab */}
        </div>
    </div>
    </div>
  </div>
   </div>);
  };

  const iconName = (item) => {
    // Automobile Bicycle Computer
    if(item?.name === 'Agriculture'){
        return 'icon-leaf';
    }
    if(item?.name === 'Automobile'){
        return 'icon-travel-car';
    }
    if(item?.name === 'Finance'){
        return 'icon-currency-dollar';
    }
    if(item?.name === 'Bike Showroom'){
        return 'icon-home';
    }
  };

  const navigateToCateoryDetails = (categoryId) => {
    const url = process.env.PUBLIC_URL + `/listings/${categoryId}`;
    navigate(url);
};

    const renderTopCategories = () => {
     return(
        <div className="banner-one__categories">
        <div className="title">
            <h4>Just looking around ? Use quick search by category :</h4>
        </div>
        <ul className="banner-one__categories-list">
            {
                categories?.map((item, index)=>{
                    return(
                     <li onClick={() => navigateToCateoryDetails(item?.id)} className="banner-one__categories-list-item" key={`topCate_${item.id}`}>
                      <Link to={process.env.PUBLIC_URL + `/`}>
                        <div className="inner">
                         <div className="icon">
                             {/* <span className="icon-mobile-devices"></span> */}
                             <span className={`${iconName(item)}`}></span>
                         </div>
                         <p>{item.name}</p>
                        </div>
                      </Link>
            </li>
                    )
                })
            }
            <li className="banner-one__categories-list-item">
                <Link to={process.env.PUBLIC_URL + `/categories`}>
                <div className="inner">
                    <div className="icon">
                        <span className="icon-plus"></span>
                    </div>
                        <p>More</p>
                    </div>
                    </Link>
            </li>

        </ul>
        </div>
        );
    };
    return (
            <>

                {/* Start Banner One */}
                <section className="banner-one">
                    <div className="banner-one__inner">
                        <div className="slider-bg-slide"
                            data-options='{ "delay": 5000, "slides": [ { "src": "assets/images/backgrounds/banner-v1-bg1bg.jpg" }, { "src": "assets/images/backgrounds/banner-v1-bg2bg.jpg" }, { "src": "assets/images/backgrounds/banner-v1-bg3bg.jpg" } ], "transition": "fade", "animation": "kenburns", "timer": false, "align": "top" }'>
                        </div>
                        <div className="slider-bg-slide-overly"></div>
                        <div className="container">
                            <div className="banner-one__content text-center">
                                <div className="title">
                                    <h2>Grow Your Business With Us</h2>
                                </div>
                                
                                {/* Start Banner One Tab Box */}
                                {renderBanner()}
                                {/* End Banner One Tab Box Tab Box */}
                                
                              {renderTopCategories()}


                                
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Banner One */}
            </>
        );
};

export default HomePageBanner;
