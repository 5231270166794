import React from 'react';
import MobileMenu from './MobileMenu';
  import { Link, NavLink} from "react-router-dom";
import { UrlConstants } from '../../utils/UrlConstants';
const Nav = () => {
    
    return (

        <>
            <div class="main-menu__inner">
                {/* <div className="login-signup">
                    <Link>Login</Link>|<Link className='disabled'>SignUp</Link>
                </div> */}
                <MobileMenu />
                <ul class="main-menu__list">
                    <li>
                        <NavLink to={process.env.PUBLIC_URL + `/`}>Home</NavLink>
                    </li>
                    <li class="dropdown">
                        <NavLink to={process.env.PUBLIC_URL + `/#`}>About Us</NavLink>
                        <ul>
                            <li><NavLink to={process.env.PUBLIC_URL + `/about`}>About Us</NavLink></li>
                            <li><Link to={process.env.PUBLIC_URL + `/who-we-are`}>Who We Are</Link></li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to={process.env.PUBLIC_URL + `/services`}>Services</NavLink>
                    </li>
                    <li >
                        <NavLink to={process.env.PUBLIC_URL + `/categories`}>Business Categories</NavLink>
                    </li>
                    <li>
                        <NavLink to={process.env.PUBLIC_URL + `/team`}>Team</NavLink>
                    </li>
                    <li>
                        <NavLink to={process.env.PUBLIC_URL + `/packages`}>Our Packages</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to={process.env.PUBLIC_URL + `#`}>Career</NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to={process.env.PUBLIC_URL + `#`}>Contact</NavLink>
                    </li> */}
                    
                     <li>
                        <NavLink to={UrlConstants.routeUrls.adminSite} target='_blank'>Business Area</NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Nav;


