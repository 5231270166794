import React from 'react';
import { Link } from 'react-router-dom';

export default class ServicesMedia extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-media-page">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Build Busines Advertising</h2>
                            <p className="sec-title__text"><strong>India</strong> is a globally networking <strong>advertising agency</strong> that builds your brand identity to make it more trustworthy and sensible to the audience. In a market that organizes a cut-throat competition,<strong>Build Busines</strong>  strives to create a unique and compelling campaign for all its customers. With its headquarters in Pune Maharashtra, they successfully operate as a highly reputed agency in India. </p>
                        </div>
                        <div className="media-management-serv">
                            <div className="media-management-title text-center">
                                <h2 className='sec-title__title'> Social Media Management Services</h2>
                                <p>Grow Your Brand With Social Media Advertising</p>
                            </div>
                            
                            <div className="media-management-text1 text-center">
                                <p>We offer a wide range of social media services in India to help businesses grow their online presence. Services include content creation and distribution, as well monitoring social media analytics for success in the online world.</p>
                            </div>
                            <div className="social-media-card">
                             <div className="row">
                                {/* Start Blog One Single */}
                                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="blog-one__single">
                                        <div className="blog-one__single-img">
                                           <img src={publicUrl+"assets/images/services/facebook-management-serv.jpg"} alt="" />
                                        </div>

                                        <div className="blog-one__single-content">
                                            <h2>Facebook Marketing Services</h2>
                                            <div className="line"></div>
                                            <div className="text">
                                                <p>Facebook is an excellent marketing tool for any business. With Build Busines, we can help you create and execute a successful campaign that will allow your content to reach more people than ever before while also growing the company in ways never thought possible! We have years of experience in the industry and can help you create and execute a successful Facebook marketing campaign.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Blog One Single */}

                                {/* Start Blog One Single */}
                                <div className="col-xl-6 col-lg-6 wow fadeInDown" data-wow-delay=".3s">
                                    <div className="blog-one__single">
                                        <div className="blog-one__single-img">
                                            <img src={publicUrl+"assets/images/services/instagram-management-serv.jpg"} alt="" />
                                        </div>

                                        <div className="blog-one__single-content">
                                            <h2>Instagram Marketing Agency</h2>
                                            <div className="line"></div>
                                            <div className="text">
                                                <p>With Build Busines’s affordable and successful Instagram management services, you can take your business or personal (@name) account to the next level. You will be able monitor posts as well run paid campaigns that are sure to put people interested in what’s going on within your niche! Build Busines knows how to make sure that it is always working for you and not against!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Blog One Single */}

                                {/* Start Blog One Single */}
                                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="blog-one__single">
                                        <div className="blog-one__single-img">
                                            <img src={publicUrl+"assets/images/services/twitter-management-serv.jpg"} alt="" />
                                        </div>

                                        <div className="blog-one__single-content">
                                            <h2>Twitter Marketing Agency</h2>
                                            <div className="line"></div>
                                            <div className="text">
                                                <p>Twitter is powerful tool that can help businesses of all sizes grow. Build Busines offers services to make sure you are getting the most out your account, including boosting followers and reaching new audiences! We can help you increase your followers, reach more of your target audience, boost your brand awareness, and generate more conversions via Twitter. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    {/* End Blog One Single */}
                                    {/* Start Blog One Single */}
                                <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="blog-one__single">
                                        <div className="blog-one__single-img">
                                            <img src={publicUrl+"assets/images/services/linked-in-management-serv.jpg"} alt="" />
                                        </div>

                                        <div className="blog-one__single-content">
                                            <h2>Linkedin Marketing Services</h2>
                                            <div className="line"></div>
                                            <div className="text">
                                                <p>LinkedIn is a great way to reach potential clients, but with most people just posting links and hoping for the best you’ll have an uphill climb. That’s where Build Busines’s expert marketers come in! Build Busines specializes specifically on LinkedIn campaigns so that your business can succeed through targeted advertising without any work from yours truly-We are always up ladder ready when it comes time for battle.With our experienced marketers, we can take you to the next level with your LinkedIn Marketing Campaigns.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Blog One Single */}
                             </div>
                            </div>
                        </div>
                        <div className="more-media-manage">
                            <div className="sec-title text-center">
                            <h2 className="sec-title__title">Are You Ready To Get More From Social Media?</h2>
                            <p className="sec-title__text">Get Started with Build Busines’s Social Media Advertising Services Today!</p>
                            </div>
                            <div className="media-management-title">
                                <h4> It fosters a collaborative partnership</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>Subscribing to a monthly SEO service means that your site is regularly updated as per the ever-changing Google’s algorithm? If you hire a new team every time you have an SEO issue, they will have to spend time learning about your site and picking up where the prior team left off. We understand the importance of monthly retainers for our clients and work diligently to provide them with the best possible return on investment.</p>
                                  <p>That time wasted costs your business, as well as your time spent in back-and-forth communication and the potential outcomes that may have been reached. Given the interwoven nature of website content, on-page SEO, technical SEO, and off-page SEO, having a single team to monitor your website’s health, do both growth and maintenance work, measure outcomes, and report on all of the above makes sense.</p>
                            </div>
                            <div className="media-management-title">
                                <h4> You get a hired vendor trusted partner</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>Having a monthly SEO retainer engagement shifts the mindset of both parties involved — instead of thinking of the arrangement as hourly labor, it is thought of as a value-driven SEO partnership. After all, you want a trusted SEO partner, not just a hired SEO vendor.</p>
                                  <p>Having a retainer type engagement is a great way to shift mindset of both parties from hourly labor into something more valuable. You want the team who will be working side by side with you , not just another hired vendor who simply ticks the checkboxes as per the SEO packages you subscribed; there’s no better feeling than having someone depend upon our expertise and skillsets!</p>
                            </div>
                            <div className="media-sub-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="media-sub-info">
                                            <div className="media-sub-img">
                                                <img src={publicUrl + "assets/images/services/time-is-money.png"} alt="" />
                                            </div>
                                            <h5> A solid investment of money and time</h5>
                                            <p>Unlike common assumptions, hiring an SEO agency on retainer encourages them to work as effectively as possible, not the other way around. This is because you are compensated based on production rather than hours worked. Will a freelancer work as effectively as possible if you pay them by the hour rather than by the project? They’re rewarded for asking more questions in meetings, sending more emails, and taking up more of your time.</p>
                                            <p>We’re the POLAR opposite! We go about putting in the effort for you month after month once we draw out your SEO strategy and goals. While we strive to keep you updated on our progress, we seldom ask for your input. We’ll only ask you to do so if it’s really necessary.</p>
                                            <p>
                                                Our SEO clients are always on the same page as us, and they understand when their job will be done. Working with us is definitely worth the time you have available.
                                             </p>
                                         </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="media-sub-info">
                                             <div className="media-sub-img">
                                                <img src={publicUrl + "assets/images/services/leads.png"} alt="" />
                                                </div>
                                            <h5>A consistent supply of high-quality leads</h5>
                                            <p>The goal of every marketing effort, whether it’s a B2B or B2C one, is to generate new business.</p>
                                            <p>Sure, the term may vary from leads to audience or clients to customers, but at the end of the day, every business wants more people to know about them, be interested in doing business with them, and be ready to buy what they’re selling. Our SEO services make you have a hassle-free time. SEO is difficult to surpass if you  want a consistent inflow of new individuals to find and express interest in what you’re selling. Our monthly SEO are a very cost-effective method to:
                                            </p>
                                            <ul>
                                                <li>Increase the number of sales leads and inquiries</li>
                                                <li>Increase the size of your audiences</li>
                                                <li>Boost brand recognition</li>
                                                 <li>Introduce new items or categories to the market</li>
                                                  <li>Gain a better understanding of your clients and consumers, and</li>
                                                   <li>Reduce the cost of acquiring new customers.</li>
                                            </ul>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div className="whats-include-manage">
                            <div className="media-management-title">
                                <h2>What's Included? </h2>
                            </div>
                            <div className="media-management-text1">
                                <p>Our monthly SEO retainers are tailored to client’s needs. The overall goal is to fit as much seamlessly as possible with your marketing team while connecting with other stakeholders to help make you an industry leader in organic traffic and leads. The SEO Retainer includes but not limited to:</p>
                            </div>

                            <div className="media-management-title">
                                <h4>Keyword Research</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We use a demand-first strategy to only recommend keywords that people are actually looking for, and help you come up with a plan for how to rank for them.</p>
                            </div>

                            <div className="media-management-title">
                                <h4>Technical Monitoring</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We not only make sure your site is optimized for Google, but also crawl it monthly to find and fix technical issues that may be impacting SEO.</p>
                            </div>
                            <div className="media-management-title">
                                <h4>Content Optimization</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>Based on the chosen keywords and current rankings, we help update the existing content on your website and optimize them for today’s SEO standards.</p>
                            </div>
                             <div className="media-management-title">
                                <h4>Internal Links & Scheme</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We add internal links and structured data to your content to help achieve better SEO results.</p>
                            </div>
                             <div className="media-management-title">
                                <h4>Competitor Analysis</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We monitor and provide a detailed analysis of your competitors, along with recommendations on how you can beat them and improve your search engine visibility.</p>
                            </div>
                             <div className="media-management-title">
                                <h4>General Research</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>If you are planning a new product launch or looking to enter a new market, we can help provide the data you need to make an informed decision.</p>
                            </div>
                             <div className="media-management-title">
                                <h4>Implementation</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We’ll take care of the updates within your CMS so you don’t have to worry about a thing!</p>
                            </div>
                              <div className="media-management-title">
                                <h4>Link Building</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We build high-quality links to your website on a monthly basis, which will help improve your rankings and traffic.</p>
                            </div>

                            <div className="media-management-title">
                                <h4>Local SEO Management</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>Our monthly NAP SEO service can help you improve your Google maps rankings performance.</p>
                            </div>

                            <div className="media-management-title">
                                <h4>Daily Rank Tracking</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>With our SEO Dashboard Upxano, you can track your website’s rankings every day and see how they are changing.</p>
                            </div>

                            <div className="media-management-title">
                                <h4>Monthly Meetings</h4>
                            </div>
                            <div className="media-management-text1">
                                <p>We do regular meetings with our clients to ensure that they are fully aware of the progress we are making and what we are planning and help strategize with you.</p>
                            </div>
                        </div>
                        <div className='expert-seo-support'>
                            <div className="sec-title text-center">
                            <h2 className="sec-title__title">Expert SEO Support</h2>
                            <p className="sec-title__text">You’ll always work with internationally trained experienced SEO Professionals.</p>
                            </div>

                             <div className="row">
                                    <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                        <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/social-media-team-expert.png"} alt="" />
                                            </div>
                                            <h5>Expert Social Media Team</h5>
                                            <p>We have a team of specialists in India that can assist you with everything from designing a social media strategy to generating engaging content.
                                             </p>
                                         </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                         <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/plan.png"} alt="" />
                                            </div>
                                                <h5>Strategy Planning</h5>
                                                <p>Our SMO team of specialists in India can assist you in developing a social media strategy that is tailored to your unique requirements and objectives.
                                                </p>
                                            </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                         <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/techniques.png"} alt="" />
                                            </div>
                                                <h5>Latest Techniques</h5>
                                                <p>We use cutting-edge tools and trends to assure the success and effectiveness of your projects.
                                                </p>
                                            </div>
                                    </div>
                                 <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                         <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/benefite.png"} alt="" />
                                            </div>
                                                <h5>Long-Term Benefits</h5>
                                                <p>Your social media presence will be stronger than ever, leading to a more profitable business.
                                                </p>
                                            </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                         <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/monitor.png"} alt="" />
                                            </div>
                                                <h5>Constant Monitoring</h5>
                                                <p>Build Busines also provides ongoing monitoring of your social media campaigns to ensure they are effective and fulfilling your objectives.
                                                </p>
                                            </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="media-sub-info text-center">
                                        <div className='media-sub-img'>
                                            <img src={publicUrl + "assets/images/services/goal-oriented.png"} alt="" />
                                            </div>
                                                <h5>Goal Oriented</h5>
                                                <p>We at Build Busines constantly seek to achieve the strongest outcomes for our clients, which is why we approach each campaign with a goal-oriented strategy.
                                                </p>
                                            </div>
                                    </div>
                                
                                </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}