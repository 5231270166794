import React from 'react';

export default class WhatWeDo extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start About One */}
                <section className="what-we-do-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">What We Do</h2>
                        </div>
                        <div className="row">
                            {/* Start Features One Single */}
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/marketing.png"} alt="#" />
                                    </div>
                                    <div className="features-one__single-title">
                                        <h2>Marketing</h2>
                                    </div>
                                     <div className="features-one__single-text">
                                        <p>Strategic marketing that illuminates brands, captivates audiences, and propels success.</p>
                                    </div>
                                  

                                </div>
                            </div>
                            {/* End Features One Single */}

                            {/* Start Features One Single */}
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                        <img src={publicUrl+"assets/images/icon/video-production.png"} alt="#" />
                                    </div>
                                    <div className="features-one__single-title">
                                        <h2>Video Production</h2>
                                    </div>
                                     <div className="features-one__single-text">
                                        <p>Crafting unforgettable Audio-Visual Experiences that resonate with your target audience.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Features One Single */}

                            {/* Start Features One Single */}
                            <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                       <img src={publicUrl+"assets/images/icon/brand-startergy.png"} alt="#" />
                                    </div>
                                    <div className="features-one__single-title">
                                        <h2>Brand Strategy</h2>
                                    </div>
                                     <div className="features-one__single-text">
                                        <p>Help you convey the brand's identity, values, and vision to forge meaningful connections.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Features One Single */}

                        </div>
                    </div>
                </section>
                {/* End About One */}
            </>
        )
    }
}