import moment from "moment/moment";


export const dateFormatting = (timestamp) => {
   const formattedDate = moment(timestamp).format('DD/MM/YYYY');
   return formattedDate;
};

export const handleWheel = (e) => {
   // Prevent changing the number input value when scrolling with the mouse wheel
   if (document.activeElement === e.target) {
       e.preventDefault();
   }
};

export const getCategoryName = (categories, shopDetailsInfo) => {
   const findCategory = categories?.find((cat) => cat.id === shopDetailsInfo?.category_id);
   return findCategory?.name;
};

export const getCategoryNameByIds = (categories, shopCategoryId) => {
   const findCategory = categories?.find((cat) => cat.id === shopCategoryId);
   return findCategory?.name;
};

export const getFormattedTime = (time) => {
   const formattedTime = moment(time, 'HH:mm:ss').format('h:mm A') ?? '';
   return formattedTime;
};

export const gotoMail = (emailAddress) => {
   const mailtoLink = `mailto:${emailAddress}`;
   window.open(mailtoLink);
};

export const gotoPhone = (phoneNumber) => {
   const telLink = `tel:${phoneNumber}`;
   window.open(telLink);
 };

 export const gotoWhatsApp = (phoneNumber, message='') => {
   const finalMessage = !message ? 'Hi wanted to know more about' : message;
   console.log('finalMessagefinalMessagefinalMessage', finalMessage);
   const whatsappLink = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(finalMessage)}`;


   window.open(whatsappLink, '_blank');
 };

export const isShopOpenNow = (openingTime, closingTime) => {
   const currentTime = moment(); // Get current time
   const openingTimeMoment = moment(openingTime, 'HH:mm:ss'); // Parse opening time
   const closingTimeMoment = moment(closingTime, 'HH:mm:ss'); // Parse closing time
   const isOpen = currentTime.isBetween(openingTimeMoment, closingTimeMoment, 'hour', '[]');
   return isOpen;
};

