import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from 'wowjs';
import { FaAngleUp } from "react-icons/fa";

//Pages
import HomeDefault from "./pages/HomeDefault";
import About from "./pages/About";
import ListingsDetailsPage from "./pages/ListingsDetailsPage";
import BusinessCategoriesPage from "./pages/BusinessCategoriesPage";
import Team from "./pages/Team";
import PackagesPage from "./pages/PackagesPage";
import Services from "./pages/Services";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import BusinessListingPage from "./pages/BusinessListingPage";
import WhoWeArePage from "./pages/WhoWeArePage";
import FaqPage from "./pages/FaqPage";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

function App() {
  useEffect(() => {
    new WOW.WOW({
        live: false
      }).init();
    }, []
  );
  return (
    <Router>
      <ScrollToTopRoute />
        <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<HomeDefault />} />
            <Route path={`${process.env.PUBLIC_URL}/about`} exact element={<About />} />
            <Route path={`${process.env.PUBLIC_URL}/listings/details/:listingId`} exact element={<ListingsDetailsPage />} />
             <Route path={`${process.env.PUBLIC_URL}/categories`} exact element={<BusinessCategoriesPage />} />
             <Route path={`${process.env.PUBLIC_URL}/listings/:id`} exact element={<BusinessListingPage />} />
            <Route path={`${process.env.PUBLIC_URL}/who-we-are`} exact element={<WhoWeArePage />} />
            <Route path={`${process.env.PUBLIC_URL}/faq`} exact element={<FaqPage />} />
            <Route path={`${process.env.PUBLIC_URL}/team`} exact element={<Team />} />
            <Route path={`${process.env.PUBLIC_URL}/packages`} exact element={<PackagesPage />} />
            <Route path={`${process.env.PUBLIC_URL}/services`} exact element={<Services />} />
            <Route path={`${process.env.PUBLIC_URL}/blog`} exact element={<Blog />} />
            <Route path={`${process.env.PUBLIC_URL}/blog-details`} exact element={<BlogDetails />} />
        <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} exact element={<TermsAndConditionsPage />} />
        <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} exact element={<PrivacyPolicyPage />} />
            <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<HomeDefault />} />
        </Routes>
        <ScrollToTop className="scrollUp" smooth top="1500" component={<FaAngleUp />} />
    </Router>
  );
}

export default App;
