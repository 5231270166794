import React from 'react';
import { Link } from 'react-router-dom';
import { daysArray } from '../../utils/Constants';
import { getFormattedTime } from '../../utils/CommonFunctions';

const ListingWorkingHours = (props) => {
    const {businessDetails} = props;

    return (
      <>
        <div className="listings-details-page__sidebar-single sidebar__working-hours wow animated fadeInUp" data-wow-delay="0.1s">
            <div className="title">
                <h2>Working Hours</h2>
            </div>
            <ul className="sidebar__working-hours-list">
              {
                daysArray.map((item, index)=>{
                    return(
                      <li key={`shopDays_${index}`}>
                        <Link>{item?.label} 
                         <span>{parseInt(businessDetails?.close_day) === item.value ? 'Closed' 
                          : `${getFormattedTime(businessDetails?.opening_time)} - ${getFormattedTime(businessDetails?.closing_time)}`
                         }</span>
                        </Link>
                      </li>
                    )
                })
               }
            </ul>
            </div>
        </>
        );
};

export default ListingWorkingHours;
