import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFormattedTime } from '../../utils/CommonFunctions';

const ListingSimilarListings = (props) => {
  const { similarBusinessList } = props;
  const navigate = useNavigate();
  let publicUrl = process.env.PUBLIC_URL+'/';

  const navigateToDetails = (item) => {
    // /listings/details/:listingId
    const url = `${publicUrl}listings/details/${item?.id}`;
    navigate(url);
    window.location.reload();
  };

  return (
     <>
      <div className="listings-details-page__sidebar-single sidebar__post wow animated fadeInUp" data-wow-delay="0.3s">
        <div className="title">
            <h2>Similar Listings </h2>
        </div>
        <ul className="sidebar__post-list list-unstyled">
         {
           similarBusinessList.map((item, index)=>{
            return <li style={{cursor: 'pointer'}} onClick={() => navigateToDetails(item)}>
             <div className="sidebar__post-image">
               <img src={publicUrl+"assets/images/blog/lp-1-1cate.jpg"} alt="" />
             </div>
             <div className="sidebar__post-content">
              <h3>
                <Link>
                    {item?.business_name}
                </Link>
               </h3>
                <span className="sidebar__post-content-meta"><i className="icon-clock"></i> {`${getFormattedTime(item?.opening_time)} - ${getFormattedTime(item?.closing_time)}`}</span>
              </div>
            </li>})
            }
        </ul>
        </div>
    </>
    )
 };

 export default ListingSimilarListings;
