import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import { Link } from 'react-router-dom';


const FaqPage = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="FAQ"
                Img="faq.jpg"
            />
            <div className="faq-wrapper">
               <section className="why-us-one">
                   <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">FAQ</h2>
                            <p className="sec-title__text">How do I get started with Build Busines Growth services?</p>
                             <p className="sec-title__text">
                                Getting started is easy! Reach out to us through our website or contact information, and our team will guide you through the process.
                            </p>
                        </div>
                        <div className="faq-question-wrapper">
                            <div className="faq-ques-sub-wrapper">
                            <div className="default-title">
                                <h5>How long does it take to see results from your marketing strategies?</h5>
                            </div>
                            <div className="default-desc">
                                <p>Results vary based on factors like your industry, goals, and the strategies implemented. However, our data-driven approach ensures you’ll start noticing positive changes sooner than you might expect.</p>
                            </div>
                            </div>
                            <div className="faq-ques-sub-wrapper">
                            <div className="default-title">
                                <h5>Can you handle businesses of all sizes?</h5>
                            </div>
                            <div className="default-desc">
                                <p>Absolutely! Whether you’re a startup or an established enterprise,  we have the expertise to tailor our services to meet your specific needs.</p>
                            </div>
                            </div>
                            <div className="faq-ques-sub-wrapper">
                            <div className="default-title">
                                <h5>How do you measure the success of your campaigns?</h5>
                            </div>
                            <div className="default-desc">
                                <p>We track key performance indicators (KPIs) relevant to your goals, such as website traffic, conversion rates, engagement metrics, and more. Regular reports will keep you informed about your campaign’s progress.</p>
                            </div>
                            </div>
                            <div className="faq-ques-sub-wrapper">
                            <div className="default-title">
                                <h5>What sets Build Busines apart from other marketing agencies Build Busines? </h5>
                            </div>
                            <div className="default-desc">
                                <p>Stands out for its data-driven strategies, creative excellence, and personalized approach. We’re not just marketers; we’re growth partners dedicated to propelling your brand to new heights.</p>
                            </div>
                            </div>
                        </div>

                        <div className="start-now-wrapeer text-center">
                            <h3>Start Your Growth Journey With Us Now</h3>
                            <Link>Start Now</Link>
                        </div>
                    </div>
                </section>
            </div>
            
            <FooterOne />
        </>
    )
}

export default FaqPage;