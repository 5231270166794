import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import TermsAndConditions from '../components/terms-and-conditions/TermsAndConditions';


const TermsAndConditionsPage = () => {
    return (
        <>
            <HeaderOne />
            <TermsAndConditions/>
            <FooterOne />
        </>
    )
}

export default TermsAndConditionsPage;