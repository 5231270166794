import React, { useEffect } from 'react';
import SingleListItem from './SingleListingItem';
import NoRecordFound from '../noRecordFound/NoRecordFound';

const MostPopularListing = (props) => {
    const { popularListings, categories } = props;
    useEffect(()=>{
        componentDidMount();
    },[]);
    
    const componentDidMount = () => {
        const $ = window.$;
        if ($('.masonary-layout').length) {
            $('.masonary-layout').isotope({
                layoutMode: 'masonry'
            });
        };
    };
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
        <>
                <section className="place-one">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className="sec-title__title">Most Popular Listings</h2>
                    </div>
                    <div className="row">
                        {/* {renderMostPularListing()} */}
                        {popularListings.length === 0 && <NoRecordFound message ='No listing found' /> }
                        {
                          popularListings.length > 0 && popularListings.map((item)=>{
                                return(
                                    <SingleListItem item={item} categories={categories} key={`mostPop_${item?.id}`}/>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default MostPopularListing;
