import React from 'react';
import { Link } from 'react-router-dom';

export default class OurValues extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Place One */}
                <section className="our-values-one">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Our Values</h2>
                        </div>
                        <div className="our-values-sub-wrap">
                              <div className="default-desc">
                               <p>At our company, we are committed to upholding the following core values:</p>
                            </div>
                            
                            <div className="default-title">
                                <h5>Innovation:</h5>
                            </div>
                            <div className="default-desc">
                                <p>We believe in constantly pushing the boundaries and exploring new ideas to provide our customers with cutting-edge technology solutions that exceed their expectations.</p>
                            </div>
                            <div className="default-title">
                                <h5>Integrity:</h5>
                            </div>
                            <div className="default-desc">
                                <p>We value honesty, transparency, and ethical behavior in all aspects of our business, from our interactions with customers to our internal <Link>decision-making processes.</Link></p>
                            </div>
                            <div className="default-title">
                                <h5>Collaboration:</h5>
                            </div>
                            <div className="default-desc">
                                <p>We believe that teamwork and collaboration are essential to achieving our goals and creating a positive work environment where everyone feels valued and supported.</p>
                            </div>
                            <div className="default-title">
                                <h5>Continuous improvement:</h5>
                            </div>
                            <div className="default-desc">
                                <p>We are committed to continuously learning and improving our skills and processes to provide the best possible solutions and services for our customers.</p>
                            </div>
                             <div className="default-title">
                                <h5>Customer focus:</h5>
                            </div>
                            <div className="default-desc">
                                <p>We prioritize our customers’ needs and satisfaction above all else, and strive to provide personalized and exceptional service that exceeds their expectations.</p>
                            </div>
                        </div>
                        <div className="our-value-live-by">
                            <div className="sec-title text-center">
                                <h2 className="sec-title__title">Here Are Our Values That We Live By</h2>
                                <p className="sec-title__text">
                                    We live and breathe our values in everything we do. They guide our decision making, the way we act, the way we communicate, and ultimately they are our “guiding light” to success.
                                </p>
                            </div>
                            <div className="row">
                               <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/passionate.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Passionate</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>We are enthusiastic about what we do and go above & beyond.</p>
                                        </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/agile.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Agile</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Quickly adapting and responding to present & future opportunities.</p>
                                        </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/growth.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Growth</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Advancing our team’s and clients through continuous upskilling and competency enhancement.</p>
                                        </div>
                                </div>
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/excellent.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Excellence</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Striving for the highest quality in all we do.</p>
                                        </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/hands.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Trustworthy</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>We’re reliable and always keep our promises.</p>
                                        </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/responsive.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Responsive</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Quick attentiveness and proaction guide our client care.</p>
                                        </div>
                                </div>
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/aacoutnable.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Accountable</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>We take full ownership of our actions and outcomes, as we affirm that ‘the buck stops with me.</p>
                                        </div>
                                </div>
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/fair-play.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Fair-play</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Our actions are guided by fairness and integrity, always striving to ‘do the right thing’.</p>
                                        </div>
                                </div>
                                </div>
                                 <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/forward-thinking.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Forward-thinking</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>We are pioneering trailblazers focused on the future.</p>
                                        </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="features-one__single text-center">
                                    <div className="features-one__single-icon">
                                         <img src={publicUrl+"assets/images/icon/innovative.png"} alt="#" />
                                    </div>
                                        <div className="features-one__single-title">
                                        <h2>Innovative</h2>
                                        </div>
                                        <div className="features-one__single-desc">
                                        <p>Our digital solutions are inspired by creativity, learning, and a passion for what we do.</p>
                                        </div>
                                </div>
                               </div>
                        
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Place One */}
            </>
        )
    }
}