import React from 'react';

export default class AboutUsInfoOne extends React.Component {
   
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="counter-one counter-one--about">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">We Specialize In What We Do</h2>
                            <p className="sec-title__text">Helping Business Get New Customers Everyday.</p>
                        </div>
                        <div className="row">
                            {/* Start Counter One Single */}
                            <div className="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="about-us-info">
                                   <div className="about-us-que">
                                        <h5>Where did it all begin?</h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>Nothing illustrates the race to the top more categorically than the search engine industry. We have worked hard to remain at the forefront for more than Three years now.</p>
                                        <p>We started in 2021, during pandemic there was lot of business were collapsed so we thought of this in 2021 and we came to these conclusion so we started this business  which is so important to highlights new ideas that will take business to next level.</p>
                                    </div>
                                    <div className="about-us-que">
                                        <h5>What is Build Busines' mission ?</h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>Build Busines strives to help businesses grow and succeed by increasing website traffic, leads and conversions. Through its marketing and sales efforts, Build Busines aims to help businesses achieve their desired outcomes.</p>
                                        <p>Creating value for clients is our sole objective and everything else revolves around it. Currently, our 55 members strong team caters to more than 190+ Upcoming  projects.</p>
                                    </div>
                                    <div className="about-us-que">
                                        <h5>What it is like working at Build Busines Advertising?</h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>It's great! We work hard and play hard. We're always innovating and trying to find new and better ways to do things. We have a lot of fun, but we also know when it's time to buckle down and get the work done. We're a close-knit team, and we all support each other no matter what. It's a great place to work!</p>
                                    </div>
                                     <div className="about-us-que">
                                        <h5>What are we proud of?</h5>
                                    </div>
                                    <div className="about-us-desc">
                                        <p>At Build Busines, we are proud of our record of providing growth via our digital transformation services. We are also proud of our team of talented and dedicated employees who work hard to help our clients achieve their marketing goals. We are constantly innovating and expanding our services to meet the needs of our clients, and we are always looking for new ways to help them succeed.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                {/* Start Counter One */}
            </>
        )
    }
}