import React from 'react';
import { Toast } from 'react-bootstrap';

function Toaster(props) {
    const {showToaster, setShowToaster, tiltle='Alert', message='', variant='danger' } = props;

  return (
    <div className="container">
      <Toast
        onClose={() => setShowToaster(false)}
        show={showToaster}
        delay={3000}
        autohide
        bg={variant}
        style={{
          position: 'fixed',
          top: '20px',
          right: '5px',
          zIndex: 99999
        }}
      >
        <Toast.Header closeButton={true}>
          <strong className="mr-auto">{tiltle}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default Toaster;
