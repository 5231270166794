import React, { useEffect, useState } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ListingDetails from '../components/listing/ListingDetails'
import FooterOne from '../common/footer/FooterOne';
import { useParams } from 'react-router-dom';
import { businessServices } from '../services';
import { ShopStatusConstant } from '../utils/Constants';
import { getLocalCategories } from '../services/tokenHelper';

const ListingsDetails = () => {
    const {listingId} = useParams('id');
    const [categories, setCategories] = useState([]);
    const [businessDetails, setBusinessDetails] = useState();
    const [similarBusinessList, setSimilarBusinessList] = useState([]);

    console.log('pssss', listingId);

    useEffect(()=>{
        getCategoriesList();
        getBusinessDetails();
        updateViewers();
    },[]);
    useEffect(()=>{
      businessDetails && getSimilarBusinessListing();
  },[businessDetails]);
    const getCategoriesList = () => {
      const cat = getLocalCategories();
      setCategories(cat);
    };

    const getBusinessDetails = () => {
      businessServices.GetShopDetailsById({
        shop_id: parseInt(listingId)
      })
      .then((res)=>{
        if(res.data.status){
          const {data} = res.data;
          setBusinessDetails(data);
          console.log('succesfully detailsbyid', data);
        }      
      })
      .catch((e)=>{
         console.log('packages err', e);
         let msg = e?.response?.data?.message;
         if(!msg && e?.response?.statusText){
           msg = e?.response?.statusText;
         }
         console.log('error loading categories', msg);
      });
  };

  const getSimilarBusinessListing = () => {
    businessServices.GetShopDetailsByCategory({
      category_id: businessDetails?.category_id,
      active_status: ShopStatusConstant.published,
      sortBy: 'viewers_count',
      limit: 4
    })
    .then((res)=>{
      if(res.data.status){
        const {data} = res.data;
        const updatedData = data.filter((item)=> item?.id != businessDetails?.id);
        console.log('updated data', updatedData);
        setSimilarBusinessList(updatedData);
      }      
    })
    .catch((e)=>{
       console.log('packages err', e);
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       console.log('error loading categories', msg);
    });
  };

  
  const updateViewers = () => {
    // /api/shop/increaseViewersCount
    businessServices.UpdateShopViewersCount({
      id: parseInt(listingId)
    })
    .then((res)=>{
      if(res.data.status){
        // const {data} = res.data;
        // setBusinessListing(data);
        //  console.log('succesfully listing', data);
        // setCategories(firstFourItems)
        
      }      
    })
    .catch((e)=>{
       let msg = e?.response?.data?.message;
       if(!msg && e?.response?.statusText){
         msg = e?.response?.statusText;
       }
       console.log('error setting viewers count', msg);
    });
  };
    const img = businessDetails?.images?.[0]?.image_url;
    let publicUrl = process.env.PUBLIC_URL + '/';
    return (
        <>
            <HeaderOne />
             {/* Regular meta tag */}
            <meta name="description" content={businessDetails?.business_name || "Build Busines Advertising"} />
            {/* Open Graph meta tags */}
            <meta property="og:description" content={businessDetails?.business_description || "Grow your business with us"} />
            <meta property="og:image" content={businessDetails?.images?.[0]?.image_url || publicUrl + "assets/images/resources/listings_details-img1.jpg"} />
            <Breadcrumb 
                heading={businessDetails?.business_name ? businessDetails?.business_name : "Listing Details"}
                Img={img}
                CompanyLogo={businessDetails?.logo_url}
                isDynamic={true}
            />
            <ListingDetails 
              businessDetails={businessDetails}
              categories={categories}
              similarBusinessList={similarBusinessList}/>
              
            <FooterOne />
        </>
    )
}

export default ListingsDetails;