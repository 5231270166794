import React from 'react';

export default class PrivacyPolicy extends React.Component {
    
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                {/* Start Counter One */}
                <section className="counter-one bg-gray-wrapper">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Privacy Policy</h2>
                            <p className="sec-title__text">Privacy Policy for the Website of Dymahi Advertising Pvt. Ltd. (WWW.DYMAHIADVERTISING.COM)</p><br/>
                            <p>At Dymahi Advertising Pvt. Ltd., safeguarding your privacy is of utmost importance to us. This Privacy Policy elucidates how we collect, utilize, disclose, and safeguard your personal information when you visit WWW.DYMAHIADVERTISING.COM ("the Site").</p>
                        </div>
                        <div className="info-wrapper-box">
                             <div className="info-sub-wrapper-box">
                                <h3>Information Collection :</h3>
                                <p>We may gather personal information from you when you willingly provide it to us, such as when you complete contact forms, subscribe to our newsletter, or correspond with us via email. This information may encompass your name, email address, phone number, and any other details you choose to share.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Use of Information :</h3>
                                <p>The personal information we collect is utilized to address your queries, furnish requested services, dispatch periodic emails concerning company news and updates, and enhance the functionality and user experience of our website.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Disclosure of Information :</h3>
                                <p>Your personal information is not sold, traded, or otherwise disclosed to external parties without your consent, except for trusted third parties who aid us in website operation, business conduct, or customer service, under the condition that they maintain the confidentiality of your information.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Security Measures :</h3>
                                <p>We employ various security measures to uphold the security of your personal information when you input, submit, or access your information on our website.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Use of Cookies :</h3>
                                <p>Cookies may be employed to enrich your browsing experience on our website. You retain the option to accept or decline cookies through your browser settings; however, disabling cookies may impact the functionality of certain sections of our website.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Third-Party Links :</h3>
                                <p>Our website may feature links to third-party websites, each governed by their own distinct privacy policies. We hold no responsibility or liability for the content and activities of these linked sites.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Changes to Privacy Policy :</h3>
                                <p>We retain the right to revise or amend this Privacy Policy at any time without prior notice. Such alterations become effective immediately upon posting the revised Privacy Policy on the Site. Your continued use of the Site after such modifications implies acceptance of the changes.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3>Contact Us :</h3>
                                <p>Should you have any queries or concerns regarding our Privacy Policy, please reach out to us using the contact details provided on our website.</p>
                            </div>
                            <div className="info-sub-wrapper-box">
                                <h3></h3>
                                <p>By utilizing our website, you consent to the terms outlined in this Privacy Policy.</p>
                            </div>
                            
                        </div>
                        <br />
                        <br />
                         <br />
                        <div className="sec-title text-center">
                            <h2 className="sec-title__title">Refund Policy</h2>
                            <p className="sec-title__text">Once payment has been made, orders cannot be cancelled. Refunds will only be issued in the event of cancellation or non-performance of service by Build Busines Advertising Dymahi Advertising Pvt. Ltd. Please note that no refunds will be given under any other circumstances.</p>
                        </div>
                    </div>
                </section>
                {/* End Counter One */}
            </>
        )
    }
}